import React from 'react';
import NextPage from "./NextPage";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

function NextPageContent({...restProps}) {
    return (
        <NextPage to="/contact" button="Get in Touch" text="" {...restProps}>
            We are hiring
        </NextPage>
    );
}

export default NextPageContent;