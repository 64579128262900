import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { store } from './modules/store'
import { Provider } from 'react-redux'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

const root = createRoot(document.getElementById('root'))

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)

reportWebVitals()
