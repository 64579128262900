import React from 'react'
import { Helmet } from 'react-helmet'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap'
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

import { getCareerItem } from '../../api/career/CareerData'
import JoidyCo from '../../components/JoidyCo'
import FadeUpTrigger from '../../animation/FadeUpTrigger'
import TextTrigger from '../../animation/TextTrigger'
import HeaderHalf from '../../components/header/HeaderHalf'
import TitleCover from '../../components/heading/title-cover/TitleCover'
import ButtonDefault from '../../components/button/button-default/ButtonDefault'
import ContactForm from '../../components/career/ContactForm'
import InfoBox from '../../components/InfoBox/InfoBox'

const JSQAAutomation = ({ data }) => {
    const nextData = getCareerItem(1)

    return (
        <>
            <Helmet>
                <title>JOIDY - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>
            {/*Start Header Half*/}
            <HeaderHalf heroContent={data}>
                <strong className="color-heading">Published </strong>{' '}
                <span> - August 18th 2022 </span>
                <div className="button-box">
                    <AnchorLink offset="50" href="#anchor">
                        <ButtonDefault
                            offset="100"
                            text="Apply for this position"
                            icon={faAngleDown}
                        />
                    </AnchorLink>
                </div>
            </HeaderHalf>
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <TitleCover>Details</TitleCover>
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Description
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    <p className="mt-20">
                        We are looking for a responsible JS QA Automation with
                        experience in Cypress.
                    </p>
                </FadeUpTrigger>
            </Container>

            <Container className="section-margin">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Requirements
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    <ul className="mt-20 ul-circle">
                        <li>1 + years of experience in automation testing</li>
                        <li>1+ years experience with JS/TS (Cypress)</li>
                        <li>Experience in creating Cypress tests</li>
                        <li>
                            Experience in writing testcases using one of the
                            Selenium-based frameworks and Cypress
                        </li>
                        <li>Experience with CI/CD</li>
                        <li>Experience with Jira</li>
                        <li>Good spoken and written English</li>
                    </ul>
                </FadeUpTrigger>
            </Container>

            <Container className="section-margin">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            It will be plus
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    <ul className="mt-20 ul-circle">
                        <li>Experience in mobile app testing</li>
                        <li>
                            Experience in API testing functional, integration,
                            regression
                        </li>
                        <li>
                            Experience with SQL queries computer science, or
                            other relevant
                        </li>
                    </ul>
                </FadeUpTrigger>
            </Container>
            {/*End Info Project*/}

            {/*Start Contact Form && Info Box*/}
            <Container id="anchor" className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <InfoBox
                        className={'background-section p-40'}
                        title={'Your dream job isn’t here?'}
                        desc={
                            'Let’s stay in touch! We’ll inform you about the best opportunities.'
                        }
                    />
                    <ContactForm />
                </JoidyCo>
            </Container>
        </>
    )
}

export default JSQAAutomation
