import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'

import HeaderNormalTow from '../components/header/HeaderNormalTow'
import PortfolioClassic from '../components/portfolio/portfolioClassic'
import NextPageContent from '../components/next/NextPageContent'

const Portfolio = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY - Portfolio</title>
            </Helmet>
            <HeaderNormalTow description="Ou22r Work">portfolio</HeaderNormalTow>
            <Container>
                <PortfolioClassic
                    col={2}
                    colGap={50}
                    colGapTablet={30}
                    rowGap={50}
                    rowGapTablet={30}
                />
            </Container>
            <NextPageContent className="section-margin" />
        </>
    )
}
export default Portfolio
