export const valuesData = [
    {
        icon: 'assets/img/values/1.svg',
        icon_hover: 'assets/img/values/1.svg',
        title: 'We Prioritize Client-Oriented Partnerships',
        tag: ['home', 'about'],
        description:
            '',
    },
    {
        icon: 'assets/img/values/2.svg',
        icon_hover: 'assets/img/values/2.svg',
        title: 'We Deliver Innovative Technical Solutions',
        tag: ['home', 'about'],
        description:
            '',
    },
    {
        icon: 'assets/img/values/3.svg',
        icon_hover: 'assets/img/values/3r.svg',
        title: 'We Foster Communication & Collaboration',
        tag: ['home', 'about'],
        description:
            '',
    },

    
]


export const aboutUsData = [
    {
        title: 'Performer',
        description: `For each project, we will choose the preferable worker who has experience working with your niche. The specialist will be selected based on several parameters: skill set, values fit, meta skills, etc. Which help you cover your goals and objectives as soon as possible.`,
        icon: 'assets/img/engagement/1.svg',
        tag: ['lol'],
    },
    {
        title: 'Dedicated \n Team',
        description: `You will have a team of IT developers, designers, copywriters, testers and other specialized specialists at your disposal. They will help create a product that will attract new customers and will work with you for as long as it takes to bring 100% results.`,
        icon: 'assets/img/engagement/2.svg',
    },
    {
        title: 'Software Product Development',
        description: `You will be able to evaluate the quality, follow the development process and monitor the execution stages during our cooperation. We will provide reports, and the development team will take responsibility to deliver the project on time.`,
        icon: 'assets/img/engagement/3.svg',
        tag: ['lol'],
    },
]