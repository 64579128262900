import React from 'react'
import ParallaxImage from '../parallax-image/ParallaxImage'
import { Container } from 'react-bootstrap'
import { dsnCN } from '../../hooks/helper'
import './style.scss'

const BoxLeft = ({ className, children, ...restProps }) => {
    return (
        <div className={dsnCN('box-left-seat v-dark-head', className)}>
            <ParallaxImage {...restProps} heightMobile="60vh" />
            <Container className="box-text p-relative" data-overlay={5}>
                <div className="inner-box">{children}</div>
            </Container>
        </div>
    )
}

export default BoxLeft
