import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import Isotope from 'isotope-layout'
import PortfolioItem from './PortfolioItem'
import { getPortfolioData } from '../../api/portfolio/PortfolioData'
import JoidyCo from '../JoidyCo'
import FilterPortfolio from './FilterPortfolio'
import { dsnCN } from '../../hooks/helper'
import './style.scss'

const PortfolioClassic = ({ className, ...restProps }) => {
    const dataPortfolio = getPortfolioData()
    const iso = useRef()
    const [filter, setFilter] = useState('*')
    const refIsotope = useRef()
    const category = new Set()
    dataPortfolio.map((p) =>
        typeof p.category === 'object'
            ? p.category.map((i) => category.add(i))
            : category.add(p.category)
    )

    useEffect(() => {
        iso.current = new Isotope(refIsotope.current, {
            itemSelector: '.portfolio-item',
        })

        return () => {
            iso.current.destroy()
        }
    })

    useEffect(() => {
        iso.current.arrange({ filter: filter })
    }, [filter])

    return (
        <section className={dsnCN('dsn-portfolio port-classic', className)}>
            <FilterPortfolio
                className="mb-80"
                categories={[...category]}
                actionFilter={setFilter}
            />
            <JoidyCo {...restProps} ref={refIsotope}>
                {dataPortfolio.map((item, index) => (
                    <PortfolioItem
                        className={
                            typeof item.category === 'object'
                                ? item.category.join(' ').toLowerCase()
                                : item.category.toLowerCase()
                        }
                        key={index}
                        portoDetails={item}
                        textButton="View Case"
                    />
                ))}
            </JoidyCo>
        </section>
    )
}

PortfolioClassic.propTypes = {
    className: PropTypes.string,
}
export default PortfolioClassic