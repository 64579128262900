import axios from 'axios'
import axiosInstance from '../../../api/axios'
import { baseConfig } from '../../../config/baseConfig'

export const sendFeedback = async (data) => {
    return await axios.post(baseConfig.API_URL + '/api/form/feedback', data)
}

export const sendVacancy = async (data) => {
    return await axiosInstance.post('/api/form/vacancy', data)
}

export const sendFeedbackEmail = async (data) => {
    data['subject'] = 'Feedback Email';
    return await axios.post(baseConfig.API_URL + '/api/send-email', data)
}

export const sendAIEmail = async (data) => {
    data['subject'] = 'AI Form Lead';
    return await axios.post(baseConfig.API_URL + '/api/send-email', data)
}


export const sendVacancyEmail = async (data) => {
    data['subject'] = 'Vacancy Email';
    return await axiosInstance.post('/api/send-email', data)
}
