import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import FadeUpTrigger from '../animation/FadeUpTrigger'
import BoxLeft from '../components/box-left/BoxLeft'
import TitleSection from '../components/heading/TitleSection'
import Services from '../components/services/Services'
import NextPage from '../components/next/NextPage'
import BoxImageVerticalParallax from "../components/box-image-vertical/BoxImageVerticalParallax";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ButtonDefault from "../components/button/button-default/ButtonDefault";
import ButtonRightIcon from '../components/button/button-right-icon/ButtonRightIcon'
import TitleCover from "../components/heading/title-cover/TitleCover";
import JoidyCo from "../components/JoidyCo";

import hero from '../assets/img/about/hero.png'

const Forecast = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY - Forecast </title>
            </Helmet>

            {/*Start Box Right Info*/}
            <BoxLeft
                className="mb-section text-center"
                src={hero}
                parallaxFrom={{ scale: 1.2 }}
                parallax={{ scale: 1 }}
                overlay={7}
            >
                <FadeUpTrigger>
                    <h1>
                        JOIDY <br /> Forecast
                    </h1>
                    <p className="mt-30 m-w750 dsn-auto">
                         Welcome to a new era of project estimation, where precision meets simplicity. <span>JOIDY Forecast</span> is your gateway to streamlined development, where uploading project requirements transforms into instant, AI-driven estimates.
                        <br></br>   
                        <br></br>                 
                    <ButtonRightIcon                        
                        target="_blank"
                        href="https://calendly.com/vitalina-miroshnyk/vitalina-joidy"
                        text="Talk to us"
                        icon={faAngleRight}
                    />
                    </p>

                </FadeUpTrigger>
            </BoxLeft>
            {/*End Box Right Info*/}

            {/*Start Steps Section*/}
            <Container className="section-margin">
                <TitleSection description="AI estimating">
                    How does it work?
                </TitleSection>
                <Services
                    col={3}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'ai-steps'}
                    Steps
                />
            </Container>
            {/*End Steps Section*/}

            {/*Start Advantages Section*/}
            <Container className="section-margin">
                <TitleSection description="Advantages of AI estimation">
                    Key Features
                </TitleSection>
                <Services
                    col={4}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'ai-advantages'}
                    Advantages
                />
            </Container>
            {/*End Advantages Section*/}



            {/*Start Steps Section*/}
            <Container className="section-margin">
                <TitleSection description="">
                    Pricing Plans
                </TitleSection>
                <Services
                    col={3}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'ai-plans'}
                    Steps
                />
            </Container>
            {/*End Steps Section*/}
            
        <NextPage to="/contact" button="Qet a quote" >
            Get our QA support
        </NextPage>
        </>
    )
}

export default Forecast
