import React from 'react';

import {Container} from "react-bootstrap";
import JoidyCo from "../components/JoidyCo";

import HeaderNormalTow from '../components/header/HeaderNormalTow'
import ContactForm from '../components/contact/ContactForm'
import { Helmet } from 'react-helmet'
import InfoBox from '../components/InfoBox/InfoBox'

const headerContent = {
    title: `Seen enough? Let’s collaborate!`,
    description: `Contact Us`,
}

const Contact = () => {
    const center = { lat: 30.0489206, lng: 31.258553 }
    return (
        <>
            <Helmet>
                <title>JOIDY - Contact Us </title>
            </Helmet>

            {/*Start Header*/}
            <HeaderNormalTow description={headerContent.description}>
                {headerContent.title}
            </HeaderNormalTow>

            {/*Start Map
            <Map
                googleMapKey="AIzaSyDMyAS2jdzj-vdgBIFaIStYOWJtSlghndg"
                defaultZoom={10}
                options={optionJoidyMap}
                height="80vh"
                defaultCenter={center}
                showMarker
            />*/}

            {/*Start Contact Form && Info Box*/}
            <Container className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <InfoBox
                        className={'background-section p-40'}
                        title={'Let’s talk'}
                        desc={
                            'Let us know how we can help'
                        }
                    />
                    <ContactForm />
                </JoidyCo>
            </Container>
        </>
    )
}

export default Contact;