import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import FadeUpTrigger from '../animation/FadeUpTrigger'
import BoxLeft from '../components/box-left/BoxLeft'
import TitleSection from '../components/heading/TitleSection'
import Services from '../components/services/Services'
import NextPage from '../components/next/NextPage'
import PortfolioSwiper from '../components/portfolio/PortfolioSwiper'
import BoxImageVerticalParallax from "../components/box-image-vertical/BoxImageVerticalParallax";
import TitleCover from "../components/heading/title-cover/TitleCover";
import JoidyCo from "../components/JoidyCo";

import hero from './mobile-development-hero.jpeg'

const MobileDevelopment = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY - Mobile Development </title>
            </Helmet>

            {/*Start Box Right Info*/}
            <BoxLeft
                className="mb-section text-center"
                src={hero}
                parallaxFrom={{ scale: 1.2 }}
                parallax={{ scale: 1 }}
                overlay={7}
            >
                <FadeUpTrigger>
                    <h1>
                        Mobile Development
                    </h1>
                    <p className="mt-30 m-w750 dsn-auto">
                    Is there a need to build and release a customized mobile app that assists the business in achieving its vision? Yeah, because  <span>every business or startup needs an app!</span> Our Mobile Development Team can help our clients solve their most complicated business challenges. 
                        <br></br>
                        <br></br>
                        It is no less important that we focus on validation to <span>avoid unnecessary features and costs,</span> ending with a beautiful app built to satisfy users.
                    </p>
                </FadeUpTrigger>
            </BoxLeft>
            {/*End Box Right Info*/}

            {/*Start Advantages Section*/}
            <Container className="section-margin">
                <TitleSection description="">
                    Why Mobile Development is needed?
                </TitleSection>
                <Services
                    col={4}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'mobile-development-advantages'}
                    Advantages
                />
            </Container>
            {/*End Advantages Section*/}

            {/*Start Steps Section*/}
            <Container className="section-margin">
                <TitleSection description="">
                    How does it work?
                </TitleSection>
                <Services
                    col={3}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'mobile-development-steps'}
                    Steps
                />
            </Container>
            {/*End Steps Section*/}

            {/*Start Box Info With Image*/}
            <BoxImageVerticalParallax            
                src="/assets/img/icon-tools/ios.svg"
                className="service-box">
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo className="FadeUpTrigger" col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>iOS</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                               iOS Development 
                            </h2>
                            <p ref={ref}>
                            We leverage the strength of the iOS platform to help our clients solve their most complicated business challenges.
Our team builds the best iOS apps that work seamlessly across all Apple devices.<br></br>
                            </p>                            
                            <h4 ref={ref}>
                            Tools &#38; Technologies we are using:
                            </h4>                       
                            
                            <div className="IconToolsContainer">
                                <h6>Language</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>Swift,  Objective C,  Dart,  JS</p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Framework / Libraries / Database</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>SwiftUI,  UIKit,  Core Data,  SQLite,  Foundation Framework, <br></br>Swift Standard Library,  Alamofire,  React Native,  Flutter</p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Tools</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>Xcode,  Instruments,  Swift Package Manager,  CocoaPods </p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Automation and Tests</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>XCTests,  UI Tests,  Quick/Nimble, Xcode Сloud,  Bitrise,  Github,  Gitlab,  CircleCI</p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Architectures</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>MVP,  MVVM,  VIP,  VIPER,  MVI/MVC</p>
                                    </div>
                                </div>
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
            </BoxImageVerticalParallax>
            {/*End  Box Info With Image*/}


            {/*Start Box Info With Image*/}
            <BoxImageVerticalParallax            
                src="/assets/img/icon-tools/android.svg"
                className="service-box">
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo className="FadeUpTrigger" col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>Android</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                               Android Development 
                            </h2>
                            <p ref={ref}>
                            Our mobile developers know how to create top-notch user experiences on Android. We design applications with attention to detail, and have deep expertise in every aspect of Android app development, resulting in extraordinary digital experiences.<br></br>
                            </p>                            
                            <h4 ref={ref}>
                            Tools &#38; Technologies we are using:
                            </h4>                       
                            
                            <div className="IconToolsContainer">
                                <h6>Language</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>Swift,  Objective C,  Dart,  JS</p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Framework / Libraries / Database</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>SwiftUI,  UIKit,  Core Data,  SQLite,  Foundation Framework, <br></br>Swift Standard Library,  Alamofire,  React Native,  Flutter</p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Tools</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>Xcode,  Instruments,  Swift Package Manager,  CocoaPods </p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Automation and Tests</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>XCTests,  UI Tests,  Quick/Nimble, Xcode Сloud,  Bitrise,  Github,  Gitlab,  CircleCI</p>
                                    </div>
                                </div>
                            </div>                  
                            
                            <div className="IconToolsContainer">
                                <h6>Architectures</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <p>MVP,  MVVM,  VIP,  VIPER,  MVI/MVC</p>
                                    </div>
                                </div>
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
            </BoxImageVerticalParallax>
            {/*End  Box Info With Image*/}


            {/*Start Steps Section*/}
            <Container className="section-margin">
                <TitleSection description="">
                    What’s included
                </TitleSection>
                <Services
                    col={2}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'mobile-development-included'}
                    Steps
                />
            </Container>
            {/*End Steps Section*/}
           
            {/*Start Portfolio Swiper*/}
            <Container as={TitleSection} description="Our Portfolio">
                Case Studies
            </Container>
            <PortfolioSwiper grabCursor />
            {/*End Portfolio Swiper*/}


        <NextPage to="/contact" button="Qet a quote" >
            Maximize your revenue with the mobile app
        </NextPage>
        </>
    )
}

export default MobileDevelopment
