import React from 'react';
import { useParams } from "react-router-dom";
import { getCareerItem } from "../../api/career/CareerData";

const CareerDetails =() => {
    const {slug} = useParams();
    const data = getCareerItem(slug, 'slug');

    if(!data) return <h1>{slug}</h1>

    return (data.component({ data }));
}

export default CareerDetails;