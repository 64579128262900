import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import CareerItem from './CareerItem'
import { getCareerData } from '../../api/career/CareerData'
import './style.scss'
import 'swiper/css'

const CareerSwiper = ({ desktop, mobile, tablet, ...restProps }) => {
    const dataCareer = getCareerData()

    return (
        <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true, el: '.swiper-pagination' }}
            spaceBetween={30}
            breakpoints={{
                320: mobile,
                768: tablet,
                992: desktop,
            }}
            {...restProps}
        >
            {dataCareer.map((item, index) => (
                <SwiperSlide key={index}>
                    <CareerItem
                        portoDetails={item}
                        textButton="What's inside?"
                    />
                </SwiperSlide>
            ))}

            <div className="swiper-pagination" />
        </Swiper>
    )
}

CareerSwiper.defaultProps = {
    desktop: {
        with: 992,
        slidesPerView: 3
    },
    tablet: {
        width: 768,
        slidesPerView: 2
    },
    mobile: {
        width: 320,
        slidesPerView: 1
    }
}

export default CareerSwiper