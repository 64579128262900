import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import displayCategories from '../../utils/utils'

const MetaPost = ({ date, ddatee, category, separate }) => (
    <Meta className="post-info">
        {date && <span className="post-date">{date} </span>}
        {ddatee && <span className="post-ddatee">{ddatee} </span>}
        {category && (
            <div className="post-cat">
                {typeof category === 'object' ? (
                    category.map((cat, index) => (
                        <span key={index} data-separate={separate}>
                            {displayCategories(cat)}
                        </span>
                    ))
                ) : (
                    <span>{category}</span>
                )}
            </div>
        )}
    </Meta>
)

MetaPost.defaultProps = {
    separate: ', ',
}

MetaPost.propTypes = {
    date: PropTypes.number,
    ddatee: PropTypes.number,
    category: PropTypes.array,
    separate: PropTypes.string,
}

export const Meta = styled.span`
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.2px;
    word-spacing: 2px;

    span {
        margin-top: 24px;
    }

    .post-date,
    .post-cat {
        display: inline-block;
    }

    .post-date {
        padding-right: 20px;
    }

    .post-cat span {
        &:not(:first-child):before {
            content: attr(data-separate);
        }
    }
`

export default MetaPost
