import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import FadeUpTrigger from '../animation/FadeUpTrigger'
import BoxLeft from '../components/box-left/BoxLeft'
import TitleSection from '../components/heading/TitleSection'
import Services from '../components/services/Services'
import NextPage from '../components/next/NextPage'
import BoxImageVerticalParallax from "../components/box-image-vertical/BoxImageVerticalParallax";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ButtonDefault from "../components/button/button-default/ButtonDefault";
import TitleCover from "../components/heading/title-cover/TitleCover";
import JoidyCo from "../components/JoidyCo";

import hero from '../assets/img/about/hero.png'

const QA = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY - QA </title>
            </Helmet>

            {/*Start Box Right Info*/}
            <BoxLeft
                className="mb-section text-center"
                src={hero}
                parallaxFrom={{ scale: 1.2 }}
                parallax={{ scale: 1 }}
                overlay={7}
            >
                <FadeUpTrigger>
                    <h1>
                        QA Manual & <br /> Automation Testing
                    </h1>
                    <p className="mt-30 m-w750 dsn-auto">
                        Almost <span>90%</span> of users leave a website or mobile app that freezes and doesn’t work well. Therefore, the business loses a huge amount of profit.
                        <br></br>
                        <br></br>
                        We guarantee that you will get an application that works stably. <span>QA will reduce the cost</span> of third-party specialists, and improve the customer’s path when buying a product.
                    </p>
                </FadeUpTrigger>
            </BoxLeft>
            {/*End Box Right Info*/}

            {/*Start Advantages Section*/}
            <Container className="section-margin">
                <TitleSection description="Advantages of quality assurance">
                    Why QA is needed?
                </TitleSection>
                <Services
                    col={4}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'qa-advantages'}
                    Advantages
                />
            </Container>
            {/*End Advantages Section*/}

            {/*Start Steps Section*/}
            <Container className="section-margin">
                <TitleSection description="Advantages of quality assurance">
                    How does it work?
                </TitleSection>
                <Services
                    col={3}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'qa-steps'}
                    Steps
                />
            </Container>
            {/*End Steps Section*/}

            {/*Start Box Info With Image*/}
            <BoxImageVerticalParallax            
                src="/assets/img/icon-tools/code.png"
                className="section-margin"
            >
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo className="FadeUpTrigger QA" col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>QA</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                                Manual Testing
                            </h2>
                            <p ref={ref}>
                            Our QA Engineers detect underlying issues, such as functional discrepancies, security breaches, integration problems, environmental issues, or traffic stress before they are delivered. We test Web Applications, Mobile Native apps, and Hybrid Applications.  <br></br>
                            </p>                            
                            <h4 ref={ref}>
                                Tools we are using:
                            </h4>                       
                            
                            <div className="IconToolsContainer">
                                <h6>Bug Tracking</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/jira.svg"></img>
                                        <p>Jira</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/trello.svg"></img>
                                        <p>Trello</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/charles.png"></img>
                                        <p>Charles</p>
                                    </div>
                                </div>
                            </div>
                            <div className="IconToolsContainer">
                                <h6>Test Management</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/testrail.svg"></img>
                                        <p>TestRail</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/jira.svg"></img>
                                        <p>Jira</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/zefyr.svg"></img>
                                        <p>Zefyr</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/testpad.svg"></img>
                                        <p>Testpad</p>
                                    </div>
                                </div>
                            </div>
                            <div className="IconToolsContainer">
                                <h6>Communication</h6>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/slack.svg"></img>
                                        <p>Slack</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/microsoft-team.svg"></img>
                                        <p>Microsoft Team</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/skype.svg"></img>
                                        <p>Skype</p>
                                    </div>
                                </div>
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo className="FadeUpTrigger QA" col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>QA</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                                Automation Testing
                            </h2>
                            <p ref={ref}>
                            Automation testing is a special technique to optimize Manual testing. Auto Testing is indispensable for software development success. By using special tools, techniques, and frameworks, automation testing can cover system quality more than manual.  <br></br>
                            </p>                            
                            <h5 ref={ref}>
                                Why automation testing is needed?
                            </h5>
                            <Services
                                col={1}
                                colMobile={1}
                                colGap={15}
                                colGapTablet={15}
                                tag={'qa-small-steps'}
                                SmallSteps
                            />
                            <div className="IconToolsContainer">
                                <h5>Tools we are using</h5>
                                <div className="IconToolsRow">
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/WebDriver.svg"></img>
                                        <p>WebDriver</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/Mocha.svg"></img>
                                        <p>Mocha</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/Jest.svg"></img>
                                        <p>Jest</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/Cypress.svg"></img>
                                        <p>Cypress</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/Selenium.svg"></img>
                                        <p>Selenium</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/Appium.svg"></img>
                                        <p>Appium</p>
                                    </div>
                                    <div className="IconToolsItem">
                                        <img src="assets/img/icon-tools/Detox.png"></img>
                                        <p>Detox</p>
                                    </div>
                                </div>
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
            </BoxImageVerticalParallax>
            {/*End  Box Info With Image*/}


            {/*Start Steps Section*/}
            <Container className="section-margin">
                <TitleSection description="">
                    What’s included
                </TitleSection>
                <Services
                    col={2}
                    colMobile={1}
                    colGap={40}
                    colGapTablet={30}
                    tag={'qa-included'}
                    Steps
                />
            </Container>
            {/*End Steps Section*/}
            
        <NextPage to="/contact" button="Qet a quote" >
            Get our QA support
        </NextPage>
        </>
    )
}

export default QA
