import { object, string } from 'yup'
import { emailRegExp, phoneRegExp } from '../../config/baseConfig'

export const schema = object().shape({
    name: string()
        .min(2, 'First name must be at least more than 2 characters')
        .required('First name is a required field'),
    email: string()
        .email('Email is a required field')
        .matches(emailRegExp, 'Should contain @ characters')
        .required('Email is a required field'),
    company: string()
        .required('Company is a required field')
        .min(5, 'Company must be at least more than 5 characters'),
    phone: string()
        .matches(phoneRegExp, 'Phone must contain just numbers')
        .required('Phone must be a "number" type')
        .min(9, 'Phone must be at least more than 8 characters'),
    message: string()
        .required('Message is a required field')
        .min(6, 'Message must be at least more than 6 characters'),
})
