import React, { useEffect, useRef, useState } from 'react'
import CareerItem from './CareerItem'
import { getCareerData } from '../../api/career/CareerData'
import JoidyCo from '../JoidyCo'
import FilterCareer from './FilterCareer'
import Isotope from 'isotope-layout'
import { dsnCN } from '../../hooks/helper'
import './style.scss'

const CareerClassic = ({ className, ...restProps }) => {
    const dataCareer = getCareerData()
    const iso = useRef()
    const [filter, setFilter] = useState('*')
    const refIsotope = useRef()
    const category = new Set()
    dataCareer.map((p) => {
        return typeof p.category === 'object'
            ? p.category.map((i) => category.add(i))
            : category.add(p.category)
    })

    useEffect(() => {
        iso.current = new Isotope(refIsotope.current, {
            itemSelector: '.career-item',
        })

        return () => {
            iso.current.destroy()
        }
    })

    useEffect(() => {
        iso.current.arrange({ filter: filter })
    }, [filter])

    return (
        <section className={dsnCN('dsn-career port-classic', className)}>
            <FilterCareer
                className="mb-80"
                categories={[...category]}
                actionFilter={setFilter}
            />
            <JoidyCo {...restProps} ref={refIsotope}>
                {dataCareer.map((item, index) => (
                    <CareerItem
                        className={
                            typeof item.category === 'object'
                                ? item.category.join(' ').toLowerCase()
                                : item.category.toLowerCase()
                        }
                        key={index}
                        portoDetails={item}
                        textButton="View Position"
                    />
                ))}
            </JoidyCo>
        </section>
    )
}

export default CareerClassic
