import React from 'react';
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import AnchorLink from 'react-anchor-link-smooth-scroll-v2'

import {getCareerItem} from "../../api/career/CareerData";
import JoidyCo from "../../components/JoidyCo";
import FadeUpTrigger from "../../animation/FadeUpTrigger";
import TextTrigger from "../../animation/TextTrigger";

import HeaderHalf from "../../components/header/HeaderHalf";
import TitleCover from '../../components/heading/title-cover/TitleCover'
import ButtonDefault from '../../components/button/button-default/ButtonDefault'
import ContactForm from '../../components/career/ContactForm'
import InfoBox from '../../components/InfoBox/InfoBox'

const QAManual = ({ data }) => {
    const nextData = getCareerItem(1)

    return (
        <>
            <Helmet>
                <title>JOIDY - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>

            {/*Start Header Half*/}
            <HeaderHalf heroContent={data}>
                <strong className="color-heading">Published </strong>{' '}
                <span> - August 17th 2022 </span>
                <div className="button-box">
                    <AnchorLink offset="50" href="#anchor">
                        <ButtonDefault
                            offset="100"
                            text="Apply for this position"
                            icon={faAngleDown}
                        />
                    </AnchorLink>
                </div>
            </HeaderHalf>
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <TitleCover>Details</TitleCover>
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            What we are looking for
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    <p className="mt-20">
                        An experienced QA engineer who has excellent analytical
                        and self-learning skills.
                    </p>
                </FadeUpTrigger>
            </Container>

            <Container className="section-margin">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Job description
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    <ul className="mt-20 ul-circle">
                        <li>
                            Analyse issues and define methods for reproducing
                            and fixing in a short cycle.
                        </li>
                        <li>
                            Creating preparation and implementation of systems
                            quality assurance reviews and development and
                            execution of test plans and test scripts.
                        </li>
                        <li>
                            Identify and implement best practices to ensure the
                            software performs as expected.
                        </li>
                        <li>
                            Verify usability, function, performance and
                            scalability.
                        </li>
                        <li>Support the development in the debug process.</li>
                    </ul>
                </FadeUpTrigger>
            </Container>

            <Container className="section-margin">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Required experience, qualities and skills
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    <ul className="mt-20 ul-circle">
                        <li>1+ years of experience as a QA Engineer</li>
                        <li>Strong analytical skills.</li>
                        <li>
                            Experience performing functional testing,
                            regressions.
                        </li>
                        <li>Experience in agile and working in sprints.</li>
                        <li>
                            Experience with bug/defect tracking tools such as
                            Jira.
                        </li>
                        <li>Fluent English speaking.</li>
                    </ul>
                </FadeUpTrigger>
            </Container>
            {/*End Info Project*/}

            {/*Start Contact Form && Info Box*/}
            <Container id="anchor" className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <InfoBox
                        className={'background-section p-40'}
                        title={'Your dream job isn’t here?'}
                        desc={
                            'Let’s stay in touch! We’ll inform you about the best opportunities.'
                        }
                    />
                    <ContactForm />
                </JoidyCo>
            </Container>
        </>
    )
}


export default QAManual;