import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import smoothScrollbarReducer from '../../features/smooth-scrollbar/smoothScrollbarSlice'
import { logger } from 'redux-logger'

const middlewares = []
const enhancers = []

if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
}

const store = configureStore({
    reducer: {
        scrollbar: smoothScrollbarReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(...middlewares),
    enhancers,
})

export default store

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type StoreDispatch = typeof store.dispatch
