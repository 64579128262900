import React from 'react';
import PropTypes from 'prop-types'

const JoidyCo = React.forwardRef((props, ref) => {
    const {
        className,
        col,
        colTablet,
        colMobile,
        colGap,
        colGapTablet,
        colGapMobile,
        rowGap,
        rowGapTablet,
        rowGapMobile,
        children,
    } = props

    const gapPx = (param) => {
        if (!isNaN(param)) return `${param}px`
        return param
    }

    return (
        <div
            style={{
                '--dsn-col-number': col,
                '--dsn-col-number-tablet': colTablet,
                '--dsn-col-number-mobile': colMobile,
                '--dsn-col-gap': gapPx(colGap),
                '--dsn-col-gap-tablet': gapPx(colGapTablet),
                '--dsn-col-gap-mobile': gapPx(colGapMobile),
                '--dsn-row-gap': gapPx(rowGap),
                '--dsn-row-gap-tablet': gapPx(rowGapTablet),
                '--dsn-row-gap-mobile': gapPx(rowGapMobile),
            }}
            className={`joidyco-layout ${className || ''}`}
            ref={ref}
        >
            {children}
        </div>
    )
})

JoidyCo.displayName = 'JoidyCo'

JoidyCo.defaultProps = {}

JoidyCo.propTypes = {
    className: PropTypes.string,
    col: PropTypes.number,
    colTablet: PropTypes.number,
    colMobile: PropTypes.number,
    colGap: PropTypes.number,
    colGapTablet: PropTypes.number,
    colGapMobile: PropTypes.number,
    rowGap: PropTypes.number,
    rowGapTablet: PropTypes.number,
    rowGapMobile: PropTypes.string,
    children: PropTypes.any,
}

export default React.memo(JoidyCo);