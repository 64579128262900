import React from 'react';
import MainScrollBar from "./MainScrollBar";
import JoidyMenu from "../components/header/menu/JoidyMenu";
import CustomCursor from "./CustomCursor";

const Layout = ({ className, children, tag: Tag = "div", activeScrollbar, ...restProps }) => {
    return (
        <Tag id="main_layout" className={className}>
            <JoidyMenu hamburger />
            {activeScrollbar ? <MainScrollBar {...restProps}> {children} </MainScrollBar> : children}
            <CustomCursor
                duration={0.5}
                durationChangeSize={0.3}
                size={20}
                scale={40}
                ease="power2.out"
            />
        </Tag>
    );
}

export default Layout;