import React from 'react'
import RedirectUrl from '../RedirectUrl/RedirectUrl'
import { InfoBoxContainer } from './InfoBox.styles'

const InfoBox = ({ className, title, desc }) => {
    return (
        <InfoBoxContainer className={className}>
            <h3>{title}</h3>
            <p className="mt-10 mb-60">{desc}</p>
            <ul className="mt-40">
                <li>
                    <span className="mr-10">E-mail</span>
                    <RedirectUrl
                        className={'info-box-item'}
                        url={'mailto:info@joidy.co'}
                        children={'info@joidy.co'}
                    />
                </li>
                <li>
                    <span className="mr-10">Linkedin</span>
                    <RedirectUrl
                        className={'info-box-item'}
                        url={
                            'http://linkedin.com/company/joidyco?utm_source=joidy.co'
                        }
                        children={'@joidyco'}
                    />
                </li>
                <li>
                    <span className="mr-10">Telegram</span>
                    <RedirectUrl
                        className={'info-box-item'}
                        url={'http://t.me/alinaqwertyu'}
                        children={'@alinaqwertyu'}
                    />
                </li>
            </ul>
        </InfoBoxContainer>
    )
}

InfoBox.defaultProps = {
    classNames: 'background-section p-30 box-info-contact',
}

export default InfoBox
