import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavLink from '../NavLink'
import { dsnCN } from '../../../hooks/helper'
import './style.scss'

const ButtonDefault = ({ icon, text, className, ...restProps }, ref) => {
    return (
        <NavLink
            className={dsnCN('btn-default', className)}
            {...restProps}
        >
            {icon && (
                <span className="icon">
                    <FontAwesomeIcon icon={icon} />
                </span>
            )}
            <span className="text">{text}</span>
        </NavLink>
    )
}

export default React.forwardRef(ButtonDefault)
