import React, { forwardRef, useRef } from 'react'
import { TextField } from '@mui/material'
import { InputStylesContainer } from './InputStyles'

const Input = forwardRef((props, ref) => {
    const fileInput = useRef(null)

    return (
        <InputStylesContainer>
            <TextField
                variant="filled"
                margin="normal"
                inputRef={ref}
                focused={true}
                fullWidth
                {...props}
            />
        </InputStylesContainer>
    )
})
Input.defaultProps = {
    type: 'text',
}

export default Input
