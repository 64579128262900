import React from 'react'
import { Container } from 'react-bootstrap';
import NavLink from "../button/NavLink";
import {dsnCN} from "../../hooks/helper";
import './style.scss'

const NextPage = ({ className , children, button ,text, ...restProps }) => {
    return (
        <div
            className={dsnCN(
                'next-page section-padding section-margin',
                className 
            )}
        >
            <Container>
                <div className="c-wrap">
                    <NavLink {...restProps}>
                        <span className="hiring">{children}</span>
                        <span className="blog">
                            {text}                          
                            <button type="submit">{button}</button>
                        </span>
                    </NavLink>
                </div>
            </Container>
        </div>
    )
}

export default NextPage