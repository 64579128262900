import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    current: null,
}

export const smoothScrollbarSlice = createSlice({
    name: 'scrollbar',
    initialState,
    reducers: {
        setScrollbar: (state, action) => {
            state.current = action.payload
        },
    },
})

export const { setScrollbar } = smoothScrollbarSlice.actions

const smoothScrollbarReducer = smoothScrollbarSlice.reducer

export default smoothScrollbarReducer
