import React, { useRef, useState } from 'react'
import { Input,  Textarea } from '../../components'
import { dsnCN } from '../../hooks/helper'
import { sendVacancyEmail } from '../../modules/store/baseForm/baseForm'
import FileUploader from '../UI/FileUploader/FileUploader'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import './style.scss'
import { object, string, mixed } from 'yup'
import {
    emailRegExp,
    engAlpabetRegExp,
    phoneRegExp,
} from '../../config/baseConfig'

const ContactForm = ({ className }) => {
    const SUPPORTED_FORMATS = ['application/pdf']

    const schema = object().shape({
        name: string()
            .min(2, 'First name must be at least more than 2 characters')
            .required('First name is a required field'),
        email: string()
            .email('Email is a required field')
            .matches(emailRegExp, 'Should contain @ characters')
            .required('Email is a required field'),
        file: mixed()
            .test('File Size is too large', (value) => {
                console.log(value[0])
                return value[0]?.size <= 5242880
            }),
    })
    const [result, setResult] = useState('')

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = async (data) => {
        const formData = new FormData()
        formData.append('file', data.file[0])

        sendVacancyEmail({
            ...data,
            file: data.file[0],
        })
            .then((res) => {
                if (res.status === 200) {
                    setResult(
                        '✅ Your Message has been successfully sent. We will contact you soon.'
                    )
                }
            })
            .catch((e) => setResult('❌ Something went wrong'))
    }
    return (
        <div className={dsnCN('form-box', className)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input__wrap controls">
                    <Input
                        {...register('name')}
                        label={'Your name *'}
                        placeholder={'Type your name'}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <Input
                        {...register('email')}
                        type={'email'}
                        label={"What's your email address? *"}
                        placeholder={'Type your Email'}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                    />

                    <Input
                        {...register('file')}
                        type={'file'}
                        label={' Attach your CV (PDF) *'}
                        accept="application/pdf"
                        error={!!errors.file}
                        helperText={errors.file?.message}
                    />

                    <Input
                        {...register('message')}
                        label={'Cover letter'}
                        placeholder={'Add a few words if you like'}
                        error={!!errors.message}
                        helperText={errors.message?.message}
                        multiline
                        maxRows={4}
                    />

                    {result && (
                        <p className="success-message mt-20">{result}</p>
                    )}

                    <div className="image-zoom">
                        <button type="submit">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ContactForm;
