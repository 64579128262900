import JSQAAutomation from '../../views/career/JSQAAutomation'
import QAManual from '../../views/career/QAManual'

const data = [
    {
        id: 1,
        title: 'QA Manual',
        slug: 'qa-manual',
        category: ['QA'],
        description:
            ' An experienced QA engineer who has excellent analytical and self-learning skills.',
        src: '/assets/img/vacancy/vacancy1/1.jpg',
        srcSlider: '/assets/img/vacancy/vacancy1/13.jpg',
        overlay: 2,
        component: (props) => <QAManual {...props} />,
    },
    {
        id: 2,
        title: 'JS QA Automation Engineer',
        slug: 'js-qa-automation',
        src: '/assets/img/vacancy/vacancy2/1.jpg',
        srcSlider: '/assets/img/vacancy/vacancy2/10.jpg',
        category: ['QA'],
        description:
            'We are looking for a responsible JS QA Automation with experience in Cypress',
        overlay: 2,

        component: (props) => <JSQAAutomation {...props} />,
    },
]

export const getCareerData = () => data

export const getCareerItem = (value, whereName = 'id') => {
    return data.find((item) => {
        if (item[whereName] === value) return item
        return null
    })
}
export const getCareerLink = (item) => {
    if (item) return item.slug && '/career/' + item.slug

    return ''
}
