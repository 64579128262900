import React from 'react'
import { Container } from 'react-bootstrap'

import HeaderNormal from '../../components/header/HeaderNormal'
import CareerClassic from '../../components/career/careerClassic'
import NextPageContent from '../../components/next/NextPageContent'
import { Helmet } from 'react-helmet'

const Career = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY - Career</title>
            </Helmet>
            <HeaderNormal
                className="background-section text-uppercase"
                description="Join the team of professionals, innovate and succeed!"
            >
                WE ARE HIRING
            </HeaderNormal>
            <Container fluid className="mt-section">
                <CareerClassic
                    col={3}
                    colGap={50}
                    colGapTablet={30}
                    rowGap={50}
                    rowGapTablet={30}
                />
            </Container>
            <NextPageContent className="section-margin" />
        </>
    )
}
export default Career
