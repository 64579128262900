const displayCategories = (category) => {
    if (category === 'UX_UI_Design') {
        return category.replace('_', '/').replace('_', ' ')
    }
    if (category === 'Mobile_App') {
        return category.replace('_', ' ')
    }
    return category
}

export default displayCategories