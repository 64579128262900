import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import JoidyCo from "../components/JoidyCo";
import ContactForm from '../components/ai/ContactForm'
import InfoBox from '../components/InfoBox/InfoBox'
import HeaderNormalTow from '../components/header/HeaderNormalTow'

const headerContent = {
    title: `Ready? Let’s estimate!`,
}

const Ai = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY - AI test</title>
            </Helmet>            
{/*Start Header*/}
<HeaderNormalTow description={headerContent.description}>
                {headerContent.title}
            </HeaderNormalTow>

            {/*Start Contact Form && Info Box*/}
            <Container className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <ContactForm />
                </JoidyCo>
            </Container>
            
        </>
    )
}

export default Ai
