import styled from 'styled-components'

export const InfoBoxContainer = styled.div.attrs((props) => ({
    className: props.className,
}))`
    position: relative;
    padding: 0;
    background-color: transparent;
    margin-bottom: 50px;

    &:before {
        content: '';
        background-image: url('../../assets/img/logo/logo-j.svg');
        background-repeat: no-repeat;
        width: 240px;
        height: 240px;
        position: absolute;
        right: 0;
        opacity: 1;
        bottom: 40px;
    }
    ul {
        li {
            display: flex;
            align-items: center;
            margin-top: 25px;
            &:not(:first-child) {
                margin-top: 20px;
            }
            span {
                color: $heading-color;
                font-weight: 600;
                font-size: 13px;
                text-transform: uppercase;
                min-width: 88px;
            }
            .info-box-item {
                position: relative;
                transition: color 0.3s ease-out;
                line-height: 1.7;
                color: #00B3C9;

                &:after,
                &:before {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(232, 232, 232, 0.2);
                    transition: transform 0.4s cubic-bezier(0.28, 0.38, 0, 0.81);
                }

                &:before {
                    transform-origin: 100% 50%;
                }

                &:after {
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    transition-delay: 0.2s;
                }

                &:hover {
                    cursor: pointer;
                    color: #ccc;
                    &:before {
                        transform: scaleX(0);
                    }

                    &:after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
`
