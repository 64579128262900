import styled from 'styled-components'

const getAlignTop = ({ children: { props } }) => {
    return props?.multiline ? 'align-items: flex-start' : 'align-items: center'
}

export const InputStylesContainer = styled.div`
    margin-top: 25px;
    .MuiFormControl-root {
        display: flex;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 20px;
        padding: 14px 0;
        flex-direction: row;
        ${getAlignTop}

        &:hover {
            border-bottom: 1px solid white;
        }

        .MuiFormLabel-root {
            color: #222;
            text-overflow: clip;
            position: relative;
            max-width: 100%;
            overflow: visible;
            margin-right: 15px;
            font-size: 15px;
            letter-spacing: 1px;
            transform: none;
        }
        .MuiInputBase-root {
            &:before,
            &:after {
                content: none;
            }
        }
        .MuiInputBase-root {
            margin: 0;
            padding: 0;

            input {
                margin: 0;
                padding: 0;
                color: #000;
                background: #f9f9f9;
                &:placeholder {
                    opacity: 1;
                    color: #222;
                    border: none;
                }
                &[type='file'] {
                    height: 30px;
                }
            }
        }
        .MuiFormHelperText-root {
            position: absolute;
            bottom: -25px;
        }
            .css-1ra8cd2-MuiInputBase-root-MuiFilledInput-root.Mui-focused {
            background: #f9f9f9;
            }
    }
`
