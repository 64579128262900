import React from 'react'
import Navbar, { Nav } from '../../nav/Navbar'
import Brand from '../../brand/Brand'
import MenuContent from './MenuContent'

const menuContent = [
    { name: 'Who we are', to: '/about' },
    {
        name: "Services",
        dropdownMenu: [ 
            { name: 'Web Development', to: '#' },
            { name: 'Mobile Development', to: '/mobile-development' },
        ]
    },
    { name: 'Case Studies', to: '/portfolio' },
    { name: 'Career', to: '/career' },
    { name: 'Contact', to: '/contact' },
]

const JoidyMenu = ({ hamburger }) => {
    let $key = 0
    const getSubMenu = (items) => {
        $key++
        if (items.dropdownMenu) {
            return (
                <Nav.Dropdown name={items.name} key={$key}>
                    {items.dropdownMenu.map((item) => getSubMenu(item))}
                </Nav.Dropdown>
            )
        }
        return (
            <Nav.Link to={items.to} key={$key}>
                {items.name}
            </Nav.Link>
        )
    }

    return (
        <Navbar hamburger={hamburger}>
            <Navbar.Brand to={'/'}>
                <Brand width="120px" height="24px" />
            </Navbar.Brand>

            <Navbar.Collapse cover="Menu">
                <Nav>{menuContent.map((item) => getSubMenu(item))}</Nav>
                <MenuContent className="section-margin" />
            </Navbar.Collapse>
        </Navbar>
    )
}

JoidyMenu.defaultProps = {
    hamburger: false,
}

export default JoidyMenu
