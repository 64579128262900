import React, {useEffect} from 'react';
import gsap from 'gsap';
import {useArrayRef} from "../hooks/DsnHooks";


const MoveTrigger = ({
    children,
    from,
    to,
    ease,
    start,
    end,
    scrub,
    markers,
    stagger,
    mobile,
    tablet,
}) => {
    const [ref, setRef] = useArrayRef()

    useEffect(() => {
        if (
            !ref.current ||
            (!mobile && window.innerWidth <= 767) ||
            (!tablet && window.innerWidth <= 991 && window.innerWidth > 767)
        )
            return
        const animation = gsap.effects.moveSection(ref.current, {
            from: from,
            to: { stagger: stagger, ...to },
            ease: ease,
            start: start,
            end: end,
            scrub: scrub,
            markers: markers,
        })

        return () => {
            animation.kill()
        }
    }, [
        ref,
        from,
        to,
        ease,
        start,
        end,
        scrub,
        markers,
        stagger,
        mobile,
        tablet,
    ])
    return (
        <React.Fragment>
            {typeof children === 'function' ? children(setRef) : children}
        </React.Fragment>
    )
}

MoveTrigger.defaultProps = {
    from: {y: 0},
    to: {y: -100},
    ease: "none",
    start: "100%",
    end: "0%",
    scrub: true,
    markers: false,
    stagger: null,
    mobile : true ,
    tablet : true
}


export default MoveTrigger;