import { object, string } from 'yup'
import { emailRegExp, phoneRegExp } from '../../config/baseConfig'

export const schema = object().shape({
    name: string()
        .min(2, 'First name must be at least more than 2 characters')
        .required('First name is a required field'),
    email: string()
        .email('Email is a required field')
        .matches(emailRegExp, 'Should contain @ characters')
        .required('Email is a required field'),
})
