import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TitleCover from '../heading/title-cover/TitleCover'
import { dsnCN } from '../../hooks/helper'
import displayCategories from '../../utils/utils'

const FilterPortfolio = ({ className, categories, actionFilter }) => {
    const [active, setActive] = useState(0)

    const getActive = ($key) =>
        Number.parseInt($key, 10) === active ? 'active' : ''

    const handleButton = (e, $index, cat) => {
        setActive($index + 1)
        actionFilter(cat)
    }

    return (
        <div className={dsnCN('dsn-filtering', className)}>
            <TitleCover className="text-center" from={{ y: 10, opacity: 0 }}>
                Portfolio
            </TitleCover>
            <div className="filtering-wrap">
                <div className="filtering">
                    <div className="selector" />
                    <button
                        type="button"
                        className={getActive(0)}
                        onClick={(e) => handleButton(e, -1, '')}
                    >
                        All
                    </button>
                    {categories &&
                        categories.map((cat, i) => {
                            return (
                                <button
                                    key={i}
                                    type="button"
                                    className={getActive(i + 1)}
                                    onClick={(e) =>
                                        handleButton(
                                            e,
                                            i,
                                            `.${cat.toLowerCase()}`
                                        )
                                    }
                                >
                                    {displayCategories(cat)}
                                </button>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

FilterPortfolio.propTypes = {
    className: PropTypes.string,
    categories: PropTypes.array,
    actionFilter: PropTypes.func,
}

export default FilterPortfolio
