import React from 'react';
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { getPortfolioItem } from "../../api/portfolio/PortfolioData";

import JoidyCo from "../../components/JoidyCo";
import FadeUpTrigger from "../../animation/FadeUpTrigger";
import TextTrigger from "../../animation/TextTrigger";
import TitleCover from "../../components/heading/title-cover/TitleCover";
import BoxGallery from "../../components/box-gallery/BoxGallery";
import ButtonDefault from "../../components/button/button-default/ButtonDefault";
import BoxImageVerticalParallax from "../../components/box-image-vertical/BoxImageVerticalParallax";
import ParallaxImage from "../../components/parallax-image/ParallaxImage";
import NextProject from "../../components/next/NextProject";
import HeaderFull from "../../components/header/HeaderFull";


const AloeTravel = ({ data }) => {
    const nextData = getPortfolioItem(6);

    return (
        <>
            <Helmet>
                <title>JOIDY - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>

            {/*Start Header Half*/}
            <HeaderFull
                heroContent={data}
                parallax={{ yPercent: 30, scale: 1.1 }}
                overlay={data.overlay}
            />
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <div className="mt-20">
                        <TitleCover>About</TitleCover>
                        <TextTrigger duration={0.8} stagger={0.1}>
                            {(ref) => (
                                <h2 className="title-section" ref={ref}>
                                    Project
                                </h2>
                            )}
                        </TextTrigger>
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <p className="mt-20" ref={ref}>
                                        Aloe Travel – Travel Agency.
                                        <br></br>
                                        Tours to anywhere in the world from Kyiv
                                        and other Ukrainian cities.
                                    </p>
                                    <ul className="mt-20">
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Domain:{' '}
                                            </strong>{' '}
                                            Travel
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Engagement Model:{' '}
                                            </strong>{' '}
                                            Team
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Duration:{' '}
                                            </strong>{' '}
                                            9 months
                                        </li>
                                    </ul>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                    <div className="mt-20">
                        <TextTrigger duration={0.8} stagger={0.1}>
                            {(ref) => (
                                <h2 className="title-section" ref={ref}>
                                    Task
                                </h2>
                            )}
                        </TextTrigger>
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <p className="mt-20" ref={ref}>
                                        <strong>1.</strong> Make a logo that
                                        will stand out from the competition and
                                        consists of two parts: font and graphic.
                                    </p>
                                    <p className="mt-20" ref={ref}>
                                        <strong>2.</strong> Develop a UX/UI
                                        website design with all the necessary
                                        functionality for easy tours searching.
                                    </p>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                </JoidyCo>
            </Container>
            {/*End Info Project*/}

            {/* Start Logotype */}
            <ParallaxImage
                src="/assets/img/project/aloetravel/2.jpg"
                caption="Logotype"
                triggerHook="top"
                parallax={{ scale: 1 }}
                parallaxFrom={{ scale: 1.1 }}
            />
            <Container className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <div className="mt-20">
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <p className="mt-20" ref={ref}>
                                        We decided to emphasize the connection
                                        between the name and the sign.
                                    </p>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                    <div className="mt-20">
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <p className="mt-20" ref={ref}>
                                        The word ALOE is also made in the style
                                        of the leaves of the plant, which refers
                                        to the company name.
                                    </p>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                </JoidyCo>
            </Container>
            {/*End Logotype */}

            <ParallaxImage
                src="/assets/img/project/aloetravel/home_screen.png"
                caption="Start Screen"
            />

            {/* Start Color and Typography */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Color Palette & Typography
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                className="section-margin container"
                src="/assets/img/project/aloetravel/color_typography.png"
                height={'80vh'}
            />
            {/*End Color and Typography */}

            {/*Start Gallery Mobile*/}
            <BoxGallery
                className="section-margin"
                col={3}
                colMobile={1}
                colGap={0}
                rowGap={0}
                images={[
                    {
                        src: '/assets/img/project/aloetravel/mobile-1.png',
                        caption: '',
                    },
                    {
                        src: '/assets/img/project/aloetravel/mobile-2.png',
                        caption: '',
                    },
                    {
                        src: '/assets/img/project/aloetravel/mobile-3.png',
                        caption: '',
                    },
                ]}
            />
            {/*End Gallery Mobile*/}

            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Tours Search
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage src="/assets/img/project/aloetravel/tours_search-1.png" />

            <ParallaxImage src="/assets/img/project/aloetravel/tours_search-2.png" />

            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Tours Search Results
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                src="/assets/img/project/aloetravel/tours_search-3.png"
                triggerHook="top"
                parallax={{ scale: 1 }}
                parallaxFrom={{ scale: 1.1 }}
            />

            {/*Start Logo Branding */}
            <BoxGallery
                col={2}
                colMobile={1}
                colGap={0}
                rowGap={0}
                images={[
                    {
                        src: '/assets/img/project/aloetravel/logo_branding-1.png',
                        caption: '',
                    },
                    {
                        src: '/assets/img/project/aloetravel/logo_branding-2.png',
                        caption: '',
                    },
                ]}
            />
            {/*End Logo Branding*/}

            {/* Start Login Form */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section mt-40" ref={ref}>
                            Login
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                src="/assets/img/project/aloetravel/login_form.png"
                triggerHook="top"
                parallax={{ scale: 1 }}
                parallaxFrom={{ scale: 1.1 }}
            />
            {/*End Login Form */}

            {/* Start Personal Account */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section mt-40" ref={ref}>
                            Personal Account
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage src="/assets/img/project/aloetravel/personal_account-1.png" />
            {/*End Personal Account */}

            {/* Start Tour Page */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section mt-40" ref={ref}>
                            Tour Page
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                src="/assets/img/project/aloetravel/tour_page.png"
                height={'100vh'}
            />
            {/*End Tour Page */}

            {/* Start Booking */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section mt-40" ref={ref}>
                            Booking
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                src="/assets/img/project/aloetravel/booking.png"
                height={'100vh'}
            />
            {/*End Booking */}

            {/*Start Gallery Components*/}
            <BoxGallery
                col={3}
                colMobile={1}
                colGap={0}
                rowGap={0}
                images={[
                    {
                        src: '/assets/img/project/aloetravel/component-1.png',
                        caption: '',
                    },
                    {
                        src: '/assets/img/project/aloetravel/component-2.png',
                        caption: '',
                    },
                    {
                        src: '/assets/img/project/aloetravel/component-3.png',
                        caption: '',
                    },
                ]}
            />
            {/*End Gallery Components*/}

            {/*Start Box Info With Image*/}
            <BoxImageVerticalParallax
                src="/assets/img/project/aloetravel/tour_mobile.png"
                className="section-margin"
            >
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>Joidy</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                                Let's get in touch
                            </h2>
                            <h6 ref={ref}>
                                Get in touch to find out what we can do
                                together.
                            </h6>
                            <p ref={ref}>
                                We promise that we won’t sell you anything that
                                we can’t deliver. <br></br>
                            </p>
                            <div className="button-box" ref={ref}>
                                <ButtonDefault
                                    text="Let's Talk"
                                    icon={faAngleRight}
                                    href="/contact"
                                />
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
            </BoxImageVerticalParallax>
            {/*End  Box Info With Image*/}
        </>
    )
}


export default AloeTravel;