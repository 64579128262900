import React from "react";
import {Container} from "react-bootstrap";
import { Helmet } from 'react-helmet'
import HeaderHalf from "../components/header/HeaderHalf";
import TitleSection from '../components/heading/TitleSection'
import PortfolioSwiper from '../components/portfolio/PortfolioSwiper'
import NextPageContent from '../components/next/NextPageContent'
import HeroSectionTow from '../components/hero-section/HeroSectionTow'
import ButtonRightIcon from '../components/button/button-right-icon/ButtonRightIcon'
import BrandClient from '../components/brand-client/BrandClient'
import CareerSwiper from '../components/career/CareerSwiper'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Services from '../components/services/Services'


const heroContent = {
    title: "Your desire is our job",
    description: "We specialize in Development and Design solutions",
    src: '/assets/video/joidy-hero.mp4',
    video: {
        poster: '/assets/img/joidy-hero-prev.jpg',
        loop: true
    },
    href: '//calendly.com/valentyna-tymko',
    textButton: 'Talk to Us',
    icon : faAngleRight

}

const Home = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY – your desire is our job</title>
            </Helmet>

        
            <HeaderHalf heroContent={heroContent} overlay={8}/>

            {/*Start Service Section*/}
            <Container className="section-margin">
                <TitleSection description="Services we provide">
                    Some of our focus areas
                </TitleSection>
                <Services
                    col={3}
                    colMobile={1}
                    colGap={180}
                    colGapTablet={30}
                    tag={'home'}
                />
            </Container>
            {/*End Service Section*/}

            {/*Start Service Section*/}
            <div className="engagement-models section-margin background-section p-1">
                <Container className="section-margin">
                    <TitleSection description="How we cooperate">
                        Engagement Models
                    </TitleSection>
                    <Services col={3} colMobile={1} models />
                </Container>
            </div>

            {/*End Service Section*/}

            {/*Start Portfolio Swiper*/}
            <Container as={TitleSection} description="Our Portfolio">
                Case Studies
            </Container>
            <PortfolioSwiper grabCursor />
            {/*End Portfolio Swiper*/}

             {/*Start Brand
             <Container className="section-margin">
                <TitleSection description="Our partners">
                        Our clients are <br/> our partners
                </TitleSection>
                <BrandClient
                    col={5}
                    colTablet={2}
                    colGap={2}
                    rowGap={2}
                />
            </Container>
           End Brand*/}

            {/* Start Career Swiper */}
            <Container className="section-margin">
                <TitleSection description="Open Vacancies">
                    We are Hiring
                    <ButtonRightIcon
                        to="/career"
                        text="All positions"
                        icon={faAngleRight}
                    />
                </TitleSection>
                <CareerSwiper Autoplay grabCursor loop autoHeight />
            </Container>
            {/* End Career Swiper */}

            <NextPageContent className="section-margin" />
        </>
    )
}

export default Home;