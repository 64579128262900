import {
    About,
    Career,
    CareerDetails,
    Contact,
    MobileDevelopment,
    Ai,
    AIChat,
    QA,
    Forecast,
    Home,
    Portfolio,
    PortfolioDetails,
} from '../../views'
import {
    faFacebookF,
    faBehance,
    faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'

export const routes = [
    {
        path: '/',
        element: <Home />,
        exact: true,
    },
    {
        path: '/portfolio',
        element: <Portfolio />,
        exact: true,
    },
    {
        path: '/portfolio/:slug',
        element: <PortfolioDetails />,
        exact: true,
    },
    {
        path: '/career',
        element: <Career />,
        exact: true,
    },
    {
        path: '/career/:slug',
        element: <CareerDetails />,
        exact: true,
    },
    {
        path: '/about',
        element: <About />,
        exact: true,
    },
    {
        path: '/qa',
        element: <QA />,
        exact: true,
    },
    {
        path: '/mobile-development',
        element: <MobileDevelopment />,
        exact: true,
    },
    {
        path: '/ai',
        element: <Ai />,
        exact: true,
    },
    {
        path: '/ai-chat',
        element: <AIChat />,
        exact: true,
    },
    {
        path: '/contact',
        element: <Contact />,
        exact: true,
    },
    {
        path: '/forecast',
        element: <Forecast />,
        exact: true,
    },
    {
        path: '/',
        link: 'http://linkedin.com/company/joidyco/?utm_source=joidy.co',
        icon: faLinkedinIn,
    },
    {
        path: '/',
        link: 'http://facebook.com/joidy.co?utm_source=joidy.co',
        icon: faFacebookF,
    },
    { path: '/', link: '#0', icon: faBehance },
    {
        path: '*',
        element: <h1 className='error-page'>Not Found</h1>,
        exact: true,
    },
]
