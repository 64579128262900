import { useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Router from './modules/router/Router'

import dsnEffect, {
    fade,
    moveSection,
    textAnimation,
} from './animation/DsnEffect'

import Layout from './layout/Layout'
import 'swiper/css/pagination'

import 'bootstrap/dist/css/bootstrap-grid.css'
import './assets/sass/style.scss'

const App = () => {
    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        dsnEffect.registerEffect(moveSection, fade, textAnimation)

        gsap.config({
            nullTargetWarn: false,
        })
        gsap.defaults({
            ease: 'none',
            duration: 1,
            overwrite: 'auto',
        })
    }, [])

    return (
        <Layout className="v-light background-main" tag="main">
            <Router />
        </Layout>
    )
}

export default App
