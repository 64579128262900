export const servicesData = [
    {
        icon: 'assets/img/services/4.svg',
        icon_hover: 'assets/img/services/4-hover.svg',
        link: '/contact',
        title: 'Blockchain Solutions',
        tag: ['home', 'about'],
        description:
            'Elevate your blockchain projects with our expertise. Whether you need a full team to build your project from the ground up—including Business Analysts, Designers, Developers, QA specialists, and more—or simply want to enhance your existing team, we&apos;re here to collaborate. We specialize in Rust, Solana, Solidity, NFT, NVP wallets, and more, ensuring your project is equipped with the best technologies and talent. We&apos;re ready to provide the resources you need, exactly when you need them.',
    },
    {
        icon: 'assets/img/services/2.svg',
        icon_hover: 'assets/img/services/2-hover.svg',
        link: '/mobile-development',
        title: 'Mobile Development',
        tag: ['home', 'about'],
        description:
            'We will develop applications for IOS and Android for your business that will help you to earn money. We will create a clear and bright design and user-friendly interface. The quality of our mobile applications will increase customer loyalty to the brand and increase your revenue.',
    },
    {
        icon: 'assets/img/services/3.svg',
        icon_hover: 'assets/img/services/3-hover.svg',
        link: '#',
        title: 'Web Development',
        tag: ['home', 'about'],
        description:
            'We will create Web Services for you under any business direction. We will help you optimize it, link it to the social networks and mobile applications and launch the conveyor to increase revenues without delay.',
    },




    {
        icon: 'assets/img/qa/advantages/money.svg',
        icon_hover: 'assets/img/qa/advantages/money.svg',
        title: 'Saving customers money',
        tag: ['qa-advantages'],
        description:
            'Detecting bugs in the early stages helps to avoid large costs in the future.',
    },
    {
        icon: 'assets/img/qa/advantages/time.svg',
        icon_hover: 'assets/img/qa/advantages/time.svg',
        title: 'Saving customers time',
        tag: ['qa-advantages'],
        description:
            'Solving problems before going live. Our Quality Assurance Engineers are involved in the development process to maintain product quality.',
    },
    {
        icon: 'assets/img/qa/advantages/quality.svg',
        icon_hover: 'assets/img/qa/advantages/quality.svg',
        title: 'Providing high product quality',
        tag: ['qa-advantages'],
        description:
            'Quality is most important to the customer. QA Engineers prevent business failures by optimizing software from the beginning',
    },
    {
        icon: 'assets/img/qa/advantages/recommendations.svg',
        icon_hover: 'assets/img/qa/advantages/recommendations.svg',
        title: 'Product recommendations',
        tag: ['qa-advantages'],
        description:
            'Customer and User satisfaction depends on the quality of a product. Your product will only be recommended if it meets the requirements and quality.',
    },






    {
        icon: 'assets/img/mobile-development/advantages/time.svg',
        icon_hover: 'assets/img/mobile-development/advantages/time.svg',
        title: 'Manage deadlines adeptly',
        tag: ['mobile-development-advantages'],
        description:
            'Our developers with 10+ years of experience know how to deal with deadlines - they have worked on more than 20 projects',
    },
    {
        icon: 'assets/img/mobile-development/advantages/quality.svg',
        icon_hover: 'assets/img/mobile-development/advantages/quality.svg',
        title: 'Deliver the quality app on a budget',
        tag: ['mobile-development-advantages'],
        description:
            'Our developers are responsible for fixing bugs so problems will be solved before going live ',
    },
    {
        icon: 'assets/img/mobile-development/advantages/flexible.svg',
        icon_hover: 'assets/img/mobile-development/advantages/flexible.svg',
        title: 'Be flexible',
        tag: ['mobile-development-advantages'],
        description:
            'We can accommodate to sometimes rather unusual needs and circumstances of startups we work with',
    },
    {
        icon: 'assets/img/qa/advantages/user-centric.svg',
        icon_hover: 'assets/img/qa/advantages/user-centric.svg',
        title: 'Be User-Centric',
        tag: ['mobile-development-advantages'],
        description:
            'Our Mobile Development Team develops user-friendly mobile apps with high scalability that attract more users to your app and eventually, more downloads. Our team believes in building more user-oriented apps as per markets trends',
    },




    {
        icon: 'assets/img/services/steps/step_01.svg',
        icon_hover: 'assets/img/services/steps/step_01.svg',
        title: 'Analysis of existing QA process',
        tag: ['qa-steps'],
        description:
            'Our QA Engineers analyze existing QA processes and reveal areas for improvement and gaps.',
    },
    {
        icon: 'assets/img/services/steps/step_02.svg',
        icon_hover: 'assets/img/services/steps/step_02.svg',
        title: 'Design QA Process and build Test Strategy',
        tag: ['qa-steps'],
        description:
            'In this stage, the team discusses the main goals, and different areas of improvement and summarizes all aspects. Based on the analysis, QA Engineers provide a new QA strategy according to business needs. ',
    },
    {
        icon: 'assets/img/services/steps/step_03.svg',
        icon_hover: 'assets/img/services/steps/step_03.svg',
        title: 'Test Specification & Environment Setup',
        tag: ['qa-steps'],
        description:
            'During this phase, the team selects and configures the test environment and product specifications. ',
    },
    {
        icon: 'assets/img/services/steps/step_04.svg',
        icon_hover: 'assets/img/services/steps/step_04.svg',
        title: 'Test Implementation and Execution',
        tag: ['qa-steps'],
        description:
            'According to Test Strategy QA creates a test plan to match acceptance criteria. Implementation of functional, non-functional, regression testing, and tracking bugs.',
    },
    {
        icon: 'assets/img/services/steps/step_05.svg',
        icon_hover: 'assets/img/services/steps/step_05.svg',
        title: 'Test Result',
        tag: ['qa-steps'],
        description:
            'Our QA Engineers share and integrate test reports and results to test management systems according to business needs.',
    },





    {
        icon: 'assets/img/services/steps/step_01.svg',
        icon_hover: 'assets/img/services/steps/step_01.svg',
        title: 'Upload Project Requirements',
        tag: ['ai-steps'],
        description:
            'Effortlessly kickstart your project by uploading your requirements. Our intuitive interface makes it easy to provide the necessary details, ensuring a smooth and seamless start to your development journey.',
    },
    {
        icon: 'assets/img/services/steps/step_02.svg',
        icon_hover: 'assets/img/services/steps/step_02.svg',
        title: 'AI-Powered Analysis',
        tag: ['ai-steps'],
        description:
            'Watch as our advanced artificial intelligence meticulously analyzes your project requirements. Experience the power of data-driven insights, enabling you to foresee challenges and make informed decisions right from the start.',
    },
    {
        icon: 'assets/img/services/steps/step_03.svg',
        icon_hover: 'assets/img/services/steps/step_03.svg',
        title: 'Receive Instant, Detailed Estimate',
        tag: ['ai-steps'],
        description:
            'Within moments, receive a detailed breakdown of your estimate. No more waiting or guesswork — JOIDYE Forecast provides real-time projections',
    },






    {
        icon: 'assets/img/qa/advantages/time.svg',
        icon_hover: 'assets/img/qa/advantages/time.svg',
        title: 'Effortless Project Initiation',
        tag: ['ai-advantages'],
        description:
            'Seamlessly kickstart your projects by easily uploading requirements, setting the foundation for efficient time estimation.',
    },
    {
        icon: 'assets/img/qa/advantages/quality.svg',
        icon_hover: 'assets/img/qa/advantages/quality.svg',
        title: 'Real-Time Time Projection',
        tag: ['ai-advantages'],
        description:
            'Instantly access detailed breakdowns of estimated project timelines, empowering you to plan and execute with time efficiency and cost-effectiveness in mind.',
    },
    {
        icon: 'assets/img/qa/advantages/recommendations.svg',
        icon_hover: 'assets/img/qa/advantages/recommendations.svg',
        title: 'AI-Driven Time Predictions',
        tag: ['ai-advantages'],
        description:
            'Leverage cutting-edge artificial intelligence for precise project analysis, providing accurate time estimates to guide your development journey.',
    },

    {
        icon: 'assets/img/qa/advantages/money.svg',
        icon_hover: 'assets/img/qa/advantages/money.svg',
        title: 'Cost-Saving Strategies',
        tag: ['ai-advantages'],
        description:
            'Implement data-driven decisions to optimize your development process, identifying opportunities to save costs while ensuring timely project delivery.',
    },







    {
        icon: 'assets/img/ai/prices/$0.svg',
        icon_hover: 'assets/img/ai/prices/$0.svg',
        title: 'Free',
        price: '0',
        tag: ['ai-plans'],
        description:
            'Ideal for individual developers or small projects.',
    },
    {
        icon: 'assets/img/ai/prices/$20.svg',
        icon_hover: 'assets/img/ai/prices/$20.svg',
        title: 'Premium',
        price: '0',
        tag: ['ai-plans'],
        description:
            'Designed for small to medium-sized teams and projects.',
    },
    {
        icon: 'assets/img/ai/prices/$100.svg',
        icon_hover: 'assets/img/ai/prices/$100.svg',
        title: 'Advanced',
        price: '0',
        tag: ['ai-plans'],
        description:
            'Tailored for larger teams and complex projects.',
    },






    {
        icon: 'assets/img/services/steps/step_01.svg',
        icon_hover: 'assets/img/services/steps/step_01.svg',
        title: 'Analyze',
        tag: ['mobile-development-steps'],
        description:
            'Our Mobile Development Team reviews existing Development processes and found out everything about your goals, product, industry, audience, and competitors.',
    },
    {
        icon: 'assets/img/services/steps/step_02.svg',
        icon_hover: 'assets/img/services/steps/step_02.svg',
        title: 'Design & build',
        tag: ['mobile-development-steps'],
        description:
            'The team discusses the main goals and areas of improvement and summarizes all aspects. Then we prepare the interactive prototype that responds to user interactions.',
    },
    {
        icon: 'assets/img/services/steps/step_03.svg',
        icon_hover: 'assets/img/services/steps/step_03.svg',
        title: 'Development',
        tag: ['mobile-development-steps'],
        description:
            'Our iOS and Android Engineers produce high-quality code and develop the application on time.',
    },
    {
        icon: 'assets/img/services/steps/step_04.svg',
        icon_hover: 'assets/img/services/steps/step_04.svg',
        title: 'Testing',
        tag: ['mobile-development-steps'],
        description:
            'We thoroughly test the performance of the project we take on to ensure its responsiveness, user-friendliness, and bulletproof security.',
    },
    {
        icon: 'assets/img/services/steps/step_05.svg',
        icon_hover: 'assets/img/services/steps/step_05.svg',
        title: 'Launch & Support',
        tag: ['mobile-development-steps'],
        description:
            'Our team shares and integrates development reports and results. You can count on our post-release support because we aim for long-term success.',
    },







    {
        icon: 'assets/img/services/steps/step_01.svg',
        icon_hover: 'assets/img/services/steps/step_01.svg',
        title: 'Test Execution speed',
        tag: ['qa-small-steps'],
        description:
            'Autotests can run on different execution environments in parallel, while manual tests should be run on each environment separately',
    },
    {
        icon: 'assets/img/services/steps/step_02.svg',
        icon_hover: 'assets/img/services/steps/step_02.svg',
        title: 'Decrease business expenses',
        tag: ['qa-small-steps'],
        description:
            'Autotests could be run on device/env farms automatically, you do not need to spend money for a manual QA Engineer to run tests on 100 different devices.',
    },
    {
        icon: 'assets/img/services/steps/step_03.svg',
        icon_hover: 'assets/img/services/steps/step_03.svg',
        title: 'Product Quality',
        tag: ['qa-small-steps'],
        description:
            'Automation testing can run a bunch of user actions to catch human errors before release, which provides you with better quality.',
    },
    {
        icon: 'assets/img/services/steps/step_04.svg',
        icon_hover: 'assets/img/services/steps/step_04.svg',
        title: 'Reporting',
        tag: ['qa-small-steps'],
        description:
            'Each run provides you with a detailed report with details of failures that can be improved in the early stages.',
    },
    {
        icon: 'assets/img/services/steps/step_04.svg',
        icon_hover: 'assets/img/services/steps/step_04.svg',
        title: 'Reporting',
        tag: ['qa-small-steps'],
        description:
            'Each run provides you with a detailed report with details of failures that can be improved in the early stages.',
    },





    {
        icon: 'assets/img/qa/inclusion/access_tools.svg',
        icon_hover: 'assets/img/qa/inclusion/access_tools.svg',
        title: 'Unlimited access to our tools and legal support',
        tag: ['qa-included'],
    },
    {
        icon: 'assets/img/qa/inclusion/customer_success.svg',
        icon_hover: 'assets/img/qa/inclusion/customer_success.svg',
        title: 'Dedicated customer success management',
        tag: ['qa-included'],
    },
    {
        icon: 'assets/img/qa/inclusion/training_onboarding.svg',
        icon_hover: 'assets/img/qa/inclusion/training_onboarding.svg',
        title: 'Manager and user training and onboarding',
        tag: ['qa-included'],
    },
    {
        icon: 'assets/img/qa/inclusion/technical_support.svg',
        icon_hover: 'assets/img/qa/inclusion/technical_support.svg',
        title: 'Technical Support',
        tag: ['qa-included'],
    },







    {
        icon: 'assets/img/qa/inclusion/access_tools.svg',
        icon_hover: 'assets/img/qa/inclusion/access_tools.svg',
        title: 'Unlimited access to our tools and legal support',
        tag: ['mobile-development-included'],
    },
    {
        icon: 'assets/img/qa/inclusion/customer_success.svg',
        icon_hover: 'assets/img/qa/inclusion/customer_success.svg',
        title: 'Dedicated customer success management',
        tag: ['mobile-development-included'],
    },
    {
        icon: 'assets/img/qa/inclusion/training_onboarding.svg',
        icon_hover: 'assets/img/qa/inclusion/training_onboarding.svg',
        title: 'Manager and user training and onboarding',
        tag: ['mobile-development-included'],
    },
    {
        icon: 'assets/img/qa/inclusion/technical_support.svg',
        icon_hover: 'assets/img/qa/inclusion/technical_support.svg',
        title: 'Technical Support',
        tag: ['mobile-development-included'],
    },



    
]

export const aboutUsData = [
    {
        title: 'Performer',
        description: `For each project, we will choose the preferable worker who has experience working with your niche. The specialist will be selected based on several parameters: skill set, values fit, meta skills, etc. Which help you cover your goals and objectives as soon as possible.`,
        icon: 'assets/img/engagement/1.svg',
        tag: ['lol'],
    },
    {
        title: 'Dedicated \n Team',
        description: `You will have a team of IT developers, designers, copywriters, testers and other specialized specialists at your disposal. They will help create a product that will attract new customers and will work with you for as long as it takes to bring 100% results.`,
        icon: 'assets/img/engagement/2.svg',
    },
    {
        title: 'Software Product Development',
        description: `You will be able to evaluate the quality, follow the development process and monitor the execution stages during our cooperation. We will provide reports, and the development team will take responsibility to deliver the project on time.`,
        icon: 'assets/img/engagement/3.svg',
        tag: ['lol'],
    },
]