import React, { useRef, useState } from 'react'
import { dsnCN } from '../../hooks/helper'
import { Input, Textarea } from '../../components'
import { sendAIEmail } from '../../modules/store/baseForm/baseForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './schema'
import './style.scss'

const ContactForm = ({ className }) => {
    const [error, setError] = useState('')
    const [result, setResult] = useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = async (data) => {
        sendAIEmail(data)
            .then((res) => {
                if (res.status === 200) {
                    setResult(true)
                    reset()
                }
            })
            .catch((e) => setError('❌ Something went wrong', e))
    }

    return (
        <div className={dsnCN('form-box', className)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input__wrap controls">
                    <Input
                        {...register('name')}
                        label={'Your name *'}
                        placeholder={'Type your name'}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />
                    <Input
                        {...register('email')}
                        type={'email'}
                        label={"What's your email address? *"}
                        placeholder={'Type your Email'}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                    />

                    {error && <p class="error">{error}</p>}
                    {result && (
                        <p className="success-message mt-20">
                            ✅ Succes! Opening a chat...
                        </p>
                    )}
                    <div className="image-zoom">
                        <button onClick={event =>  window.location.href='https://joidy.co/ai-chat/'} type="submit">Continue</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ContactForm
