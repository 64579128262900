import React from 'react'
import PropTypes from 'prop-types'

const RedirectUrl = ({ className, url, children }) => {
    const handleRedirect = () => (window.location.href = url)
    return (
        <div
            className={className}
            onClick={handleRedirect}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </div>
    )
}

RedirectUrl.propTypes = {}

export default RedirectUrl
