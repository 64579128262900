import React from 'react'
import './style.scss'
import TestimonialItem from './TestimonialItem';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import MoveTrigger from "../../animation/MoveTrigger";
import {Pagination, SwiperOptions} from "swiper";
import {dsnCN} from "../../hooks/helper";


const TestimonialDetails = [
    {
        name: 'Mykhailo R.',
        label: 'CEO of Aloe Travel',
        src: 'assets/img/avatar/aloetravel-review_logo.png',
        description: `"We started cooperating with Joidy, because we wanted to stand out among the competitors and improve the functionality of the website. Very grateful for your work, because due to JOIDY we have increased the number of bookings through the website and gained relevant audience. And most importantly - the concept of the new design and logo increased recognition of the company on the market. Thank you."`,
    },
    {
        name: 'Kateryna H.',
        label: 'Representative of Caper Travel',
        src: 'assets/img/avatar/capertravel-review_logo.png',
        description: `"We have been working on the tour operators market since 1998 and we know how important it is to reach digital technologies to be among the leaders in the segment. Thank you JOIDY for the properly done work. I can say how well they analyzed and understood the needs of our target audience. They developed a new website for us and requested a unique idea to search for tours. The managers always kept in touch, and the developers and designers have done everything on the agreed terms. The website is good at converting clients and works without any interruptions. We strongly recommend it."`,
    },
    {
        name: 'Bogdan T.',
        label: 'CTO of Voda 24',
        src: 'assets/img/avatar/voda24-review_logo.png',
        description: `"I appreciate Joidy's work and would like to share my impressions of cooperating with them. I am the owner of a water delivery service and contacted the company to develop the app. Everything is made with a clear approach and on time, we tested and analyzed the product with a different design for the target audience and settled on the best draft for us. Digitalization was a success! . Therefore, we continue to cooperate and thank the company!"`,
    },
]

const Testimonial = ({ className, title, ...restProps }) => {
    return (
        <section
            className={dsnCN(
                'dsn-testimonial background-section testimonial-one',
                className
            )}
        >
            <div className="testimonial-inner ">
                <div className="title-box">
                    {title && (
                        <MoveTrigger
                            from={{ y: 0, opacity: 0 }}
                            to={{ y: -60, opacity: 1 }}
                            mobile={false}
                        >
                            {(ref) => (
                                <h2
                                    className="title-section p-relative z-index-1"
                                    ref={ref}
                                >
                                    {title}
                                </h2>
                            )}
                        </MoveTrigger>
                    )}
                </div>
                <div className="content-box">
                    <Swiper
                        modules={[Pagination]}
                        pagination={{
                            clickable: true,
                            el: '.swiper-pagination',
                        }}
                        slidesPerView={1}
                        {...restProps}
                    >
                        {TestimonialDetails.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <TestimonialItem
                                        src={item.src}
                                        authorName={item.name}
                                        label={item.label}
                                        description={item.description}
                                    />
                                </SwiperSlide>
                            )
                        })}

                        <div className="swiper-pagination" />
                    </Swiper>
                </div>
            </div>
        </section>
    )
}


export default Testimonial;