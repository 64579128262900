import React from 'react'
import {Helmet} from "react-helmet";
import {Container} from "react-bootstrap";

import {getPortfolioItem} from "../../api/portfolio/PortfolioData";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import JoidyCo from "../../components/JoidyCo";
import FadeUpTrigger from "../../animation/FadeUpTrigger";
import TextTrigger from "../../animation/TextTrigger";
import TitleCover from "../../components/heading/title-cover/TitleCover";
import ButtonLink from "../../components/button/button-link/ButtonLink";
import BoxGallery from "../../components/box-gallery/BoxGallery";
import ButtonDefault from "../../components/button/button-default/ButtonDefault";
import BoxImageVerticalParallax from "../../components/box-image-vertical/BoxImageVerticalParallax";
import ParallaxImage from "../../components/parallax-image/ParallaxImage";
import NextProject from "../../components/next/NextProject";
import HeaderFull from "../../components/header/HeaderFull";

const WhatIfGold = ({data}) => {
    const nextData = getPortfolioItem(6);
    return (
        <>
            <Helmet>
                <title>JOIDY - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>
            {/*Start Header Half*/}
            <HeaderFull
                heroContent={data}
                parallax={{ yPercent: 30, scale: 1.1 }}
                overlay={data.overlay}
            >
                <strong className="color-heading">
                    <ButtonLink
                        href="http://capertravel.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {' '}
                        VISIT SITE
                    </ButtonLink>
                </strong>
            </HeaderFull>
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <TitleCover>About</TitleCover>
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            What is Caper Travel?
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    {(ref) => (
                        <>
                            <p className="mt-20" ref={ref}>
                                Caper Travel is one of the best tour operators
                                in India. The company was founded in 1998. Since
                                its foundation, it has been the winner of the
                                National Tourism Award 5 times.<br></br>The
                                company offers group tours and charter programs,
                                promotes the business travel and MICE market,
                                and develops individual programs for any
                                request.<br></br>We have created a corporate
                                identity and a full-fledged website with a
                                unique engine of tours search.
                            </p>
                            <ul className="mt-20">
                                <li className="mt-1" ref={ref}>
                                    <strong className="color-heading">
                                        Domain:{' '}
                                    </strong>{' '}
                                    Travel
                                </li>
                                <li className="mt-1" ref={ref}>
                                    <strong className="color-heading">
                                        Engagement Model:{' '}
                                    </strong>{' '}
                                    Team
                                </li>
                                <li className="mt-1" ref={ref}>
                                    <strong className="color-heading">
                                        Duration:{' '}
                                    </strong>{' '}
                                    9 months
                                </li>
                                <li className="mt-1" ref={ref}>
                                    <strong className="color-heading">
                                        Technologies:{' '}
                                    </strong>{' '}
                                    .NET Core, React.js, Node.js, AWS, Flutter
                                </li>
                            </ul>
                        </>
                    )}
                </FadeUpTrigger>
            </Container>
            {/*End Info Project*/}

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/project3/3.jpg"
                triggerHook="top"
                parallax={{ scale: 1 }}
                parallaxFrom={{ scale: 1.1 }}
            />

            <ParallaxImage
                src="/assets/img/project/project3/2.jpg"
                caption="User-Friendly Navigation"
            />

            <ParallaxImage
                src="/assets/img/project/project3/4.jpg"
                caption="Search System for Travel"
            />

            {/*Start Gallery List*/}
            <BoxGallery
                className="section-margin"
                col={3}
                colMobile={1}
                colGap={0}
                rowGap={0}
                images={[
                    { src: '/assets/img/project/project3/5.jpg', caption: '' },
                    { src: '/assets/img/project/project3/6.jpg', caption: '' },
                    { src: '/assets/img/project/project3/7.jpg', caption: '' },
                ]}
            />
            {/*End Gallery List*/}

            {/*Start Box Info With Image*/}
            <BoxImageVerticalParallax
                src="/assets/img/project/project3/8.png"
                className="section-margin"
            >
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>Joidy</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                                Let's get in touch
                            </h2>
                            <h6 ref={ref}>
                                Get in touch to find out what we can do
                                together.
                            </h6>
                            <p ref={ref}>
                                We promise that we won’t sell you anything that
                                we can’t deliver. <br></br>
                            </p>
                            <div className="button-box" ref={ref}>
                                <ButtonDefault
                                    text="Let's Talk"
                                    icon={faAngleRight}
                                    href="/contact"
                                />
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
            </BoxImageVerticalParallax>
            {/*End  Box Info With Image*/}
        </>
    )
}


export default WhatIfGold;