import AloeTravel from '../../views/portfolio/AloeTravel'
import AvtoBrama from '../../views/portfolio/AvtoBrama'
import ReStyling from '../../views/portfolio/ReStyling'
import ToastReel from '../../views/portfolio/ToastReel'
import CaperTravel from '../../views/portfolio/CaperTravel'
import NileKabutha from '../../views/portfolio/NileKabutha'
import SleepWalker from '../../views/portfolio/SleepWalker'
import NovaraConic from '../../views/portfolio/NovaraConic'
import Voda24 from '../../views/portfolio/Voda24'

const data = [
    {
        id: 1,
        title: 'AloeTravel',
        slug: 'aloe-travel',
        project_logo: '/assets/img/project/aloetravel/project-logo.svg',
        category: ['UX_UI_Design', 'Branding', 'Development'],
        description:
            'Experience a seamless design process from concept to production.',
        src: '/assets/img/project/aloetravel/1.jpg',
        srcSlider: '/assets/img/project/project1/13.jpg',
        overlay: 5,
        component: (props) => <AloeTravel {...props} />,
    },
    {
        id: 2,
        title: 'Voda24',
        slug: 'voda24',
        category: ['UX_UI_Design', 'Mobile_App'],
        src: '/assets/img/project/voda24/hero.png',
        project_logo: '/assets/img/project/voda24/project-logo.svg',
        srcSlider: '/assets/img/project/voda24/10.jpg',
        description: 'UX UI Design and Development for water delivery IOS app',
        overlay: 5,

        component: (props) => <Voda24 {...props} />,
    },
    {
        id: 3,
        title: 'CaperTravel',
        slug: 'caper-travel',
        project_logo: '/assets/img/project/project3/project-logo.svg',
        category: ['UX_UI_Design', 'Development'],
        src: '/assets/img/project/project3/1.jpg',
        srcSlider: '/assets/img/project/project3/3.png',
        description:
            'We were faced with the branding that would stand out from the competition and consist of two parts: font and graphics.',
        overlay: 5,
        component: (props) => <CaperTravel {...props} />,
    },
    {
        id: 4,
        title: 'AvtoBrama',
        slug: 'avtobrama',
        src: '/assets/img/project/avtobrama/hero.png',
        project_logo: '/assets/img/project/avtobrama/project-logo.svg',
        srcSlider: '/assets/img/project/avtobrama/hero.png',
        category: ['UX_UI_Design', 'Development'],
        description:
            'Cal was first. The first public university in the great state of California. They are the pioneers. They are the trailblazers who started it all.',
        overlay: 6,

        component: (props) => <AvtoBrama {...props} />,
    },
]

export const getPortfolioData = () => data

export const getPortfolioItem = (value, whereName = 'id') => {
    return data.find((item) => {
        if (item[whereName] === value) return item
        return null
    })
}
export const getPortfolioLink = (item) => {
    if (item) return item.slug && '/portfolio/' + item.slug

    return ''
}
