import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import FadeUpTrigger from '../animation/FadeUpTrigger'
import BoxLeft from '../components/box-left/BoxLeft'
import TitleSection from '../components/heading/TitleSection'
import NextPageContent from '../components/next/NextPageContent'
import Testimonial from '../components/testimonial/Testimonial'
import hero from '../assets/img/about/hero.png'
import Services from '../components/services/Services'
import Values from '../components/values/Values'

const About = () => {
    return (
        <>
            <Helmet>
                <title>JOIDY - About Us </title>
            </Helmet>

            {/*Start Box Right Info*/}
            <BoxLeft
                className="mb-section text-center"
                src={hero}
                parallaxFrom={{ scale: 1.2 }}
                parallax={{ scale: 1 }}
                overlay={7}
            >
                <FadeUpTrigger>
                    <h1>
                        Hello we are, <br /> JOIDY.
                    </h1>
                    <p className="mt-30 m-w750 dsn-auto">
                        JOIDY is a dynamic and forward-thinking company at the forefront 
                        of web and mobile development. We’re a passionate team of developers, 
                        designers, and project managers, committed to turning your vision into a 
                        digital masterpiece. Our diverse range of services reflects our dedication 
                        to providing top-tier solutions for your projects.
                        <br></br>
                    </p>
                </FadeUpTrigger>
            </BoxLeft>
            {/*End Box Right Info*/}

            {/*Start Service Section*/}
            <Container className="section-margin">
                <TitleSection description="Services we provide">
                    Some of our focus areas
                </TitleSection>
                <Services
                col={2}
                colMobile={1}                
                tag={'about'}
                />
            </Container>
            {/*End Service Section*/}

            {/*Start Value Section*/}
            <Container className="section-margin">
                <TitleSection description="Our client values">
                    Our Commitment to You
                </TitleSection>
                <Values
                col={3}
                colMobile={1}                
                tag={'about'}
                />
            </Container>
            {/*End Value Section*/}

            {/*Start Testimonial*/}
            <Container className="section-margin">
                <Testimonial
                    autoHeight
                    loop
                    grabCursor
                    title="Feedback from our clients"
                />
            </Container>
            {/*End Testimonial*/}

            <NextPageContent className="section-margin" />
        </>
    )
}

export default About
