import React, { useState, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import useEffectLocation from '../../hooks/useEffectLocation'
import { routes } from './routers'
import './style.scss'

const Footer = React.lazy(() => import('../../components/footer/Footer'))

const Router = () => {
    const [transPage, setTransPage] = useState('in')
    const scrollbar = useSelector((state) => state.scrollbar)

    const location = useEffectLocation((l) => {
        setTransPage('out')
    })

    const onAnimateEnd = () => {
        if (transPage !== 'out') return
        scrollbar.current?.scrollTo(0, 0, 0)
        window.scrollTo({ left: 0, top: 0 })
        setTransPage('in')
    }


    return (
        <div
            id="dsn-content"
            className={`dsn-transition-page dsn-animate-${transPage}`}
            onAnimationEnd={onAnimateEnd}
        >
            <Suspense fallback={<div className="background-main h-100-v" />}>
                <Routes location={location}>
                    {routes.map((route) => (
                        <Route {...route} />
                    ))}
                </Routes>
            </Suspense>

            <Footer />
        </div>
    )
}

export default React.memo(Router)
