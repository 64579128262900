import React from 'react';
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {link: "http://linkedin.com/company/joidyco/?utm_source=joidy.co", name: "LinkedIn."},
        {link: "https://clutch.co/profile/joidy?utm_source=joidy.co", name: "Clutch."}
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden">
                        <a className="link-hover" href="mailto:info@joidy.co "
                           data-hover-text="info@joidy.co">info@joidy.co</a>
                           <a className="link-hover" href="http://t.me/alinaqwertyu"
                              data-hover-text="http://t.me/alinaqwertyu">      Telegram: @alinaqwertyu</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;