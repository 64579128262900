import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavLink from '../NavLink'
import { dsnCN } from '../../../hooks/helper'
import './style.scss'

const ButtonDefault = ({ icon, text, className, ...restProps }, ref) => {
    return (
        <NavLink
            className={dsnCN('btn-right-icon', className)}
            {...restProps}
            ref={ref}
        >
            <span className="text">{text}</span>
            {icon && (
                <span className="icon">
                    <FontAwesomeIcon icon={icon} />
                </span>
            )}
        </NavLink>
    )
}

export default React.forwardRef(ButtonDefault)
