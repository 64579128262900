import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { getPortfolioItem } from '../../api/portfolio/PortfolioData'

import JoidyCo from '../../components/JoidyCo'
import FadeUpTrigger from '../../animation/FadeUpTrigger'
import TextTrigger from '../../animation/TextTrigger'
import TitleCover from '../../components/heading/title-cover/TitleCover'
import BoxGallery from '../../components/box-gallery/BoxGallery'
import ButtonDefault from '../../components/button/button-default/ButtonDefault'
import BoxImageVerticalParallax from '../../components/box-image-vertical/BoxImageVerticalParallax'
import ParallaxImage from '../../components/parallax-image/ParallaxImage'
import NextProject from '../../components/next/NextProject'
import HeaderFull from '../../components/header/HeaderFull'

const Voda24 = ({ data }) => {
    const nextData = getPortfolioItem(6)

    return (
        <>
            <Helmet>
                <title>JOIDY - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>

            {/*Start Header Half*/}
            <HeaderFull
                heroContent={data}
                parallax={{ yPercent: 30, scale: 1.1 }}
                overlay={data.overlay}
            />
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <div className="mt-20">
                        <TitleCover>About</TitleCover>
                        <TextTrigger duration={0.8} stagger={0.1}>
                            {(ref) => (
                                <h2 className="title-section" ref={ref}>
                                    Project
                                </h2>
                            )}
                        </TextTrigger>
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <p className="mt-40" ref={ref}>
                                        An application that provides a water
                                        delivery service to your home or office.
                                    </p>
                                    <ul className="mt-40">
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Domain:{' '}
                                            </strong>{' '}
                                            Health and wellness
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Engagement Model:{' '}
                                            </strong>{' '}
                                            Team
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Duration:{' '}
                                            </strong>{' '}
                                            5 months
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Technologies:{' '}
                                            </strong>{' '}
                                            PostgreSQL, Redux, React Native,
                                            Firebase, SQLite, AdMob, RoR
                                        </li>
                                    </ul>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                    <div className="mt-20">
                        <TextTrigger duration={0.8} stagger={0.1}>
                            {(ref) => (
                                <h2 className="title-section" ref={ref}>
                                    Challenge
                                </h2>
                            )}
                        </TextTrigger>
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <h5 className="mt-40" ref={ref}>
                                        Task
                                    </h5>
                                    <p className="mt-10" ref={ref}>
                                        The client wanted to create an app for
                                        IOS which would help customers order
                                        water in a few clicks.
                                    </p>
                                    <h5 className="mt-40" ref={ref}>
                                        Result
                                    </h5>
                                    <p className="mt-10" ref={ref}>
                                        The design has been implemented into a
                                        functional iOS application.
                                    </p>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                </JoidyCo>
            </Container>
            {/*End Info Project*/}

            {/* Start UX Flow */}
            <ParallaxImage
                src="/assets/img/project/voda24/uxflow.png"
                caption="UX Flow"
                triggerHook="bottom"
                parallax={{ scale: 1.2 }}
                parallaxFrom={{ scale: 1 }}
            />
            {/*End UX Flow */}

            {/* Start Wireframes */}

            {/* Start Splash Screen */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Wireframes
                        </h2>
                    )}
                </TextTrigger>
            </Container>
            <ParallaxImage
                src="/assets/img/project/voda24/wireframes.png"
                caption="Wireframes"
                triggerHook="bottom"
                parallax={{ scale: 1.2 }}
                parallaxFrom={{ scale: 1 }}
            />
            {/*End Wireframes */}

            {/* Start Onboarding Screen */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Onboarding Screen
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    {(ref) => (
                        <>
                            <p className="mt-20" ref={ref}>
                                Welcome screen and animated transition to the
                                login screen
                            </p>
                        </>
                    )}
                </FadeUpTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/voda24/onboarding.png"
            />
            {/* Onboarding Screen */}

            {/* Start Account Screen */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Personal Account
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    {(ref) => (
                        <>
                            <p className="mt-20" ref={ref}>
                                User profile with personal information,
                                including the settings: payment methods,
                                shipping address, and reminders.
                            </p>
                        </>
                    )}
                </FadeUpTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/voda24/personal_account.png"
            />
            {/* Account Screen */}

            {/* Start Water List & Cart */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Water list & Cart
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    {(ref) => (
                        <>
                            <p className="mt-20" ref={ref}>
                                Select the product category and product list.
                                <br></br>
                                Product details and items in the cart.
                            </p>
                        </>
                    )}
                </FadeUpTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/voda24/waterlist_cart.png"
            />
            {/* End Water List & Cart */}

            {/* Start Checkout */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Checkout
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    {(ref) => (
                        <>
                            <p className="mt-20" ref={ref}>
                                Payment process, date & time picker, address,
                                and additional options.
                            </p>
                        </>
                    )}
                </FadeUpTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/voda24/checkout.png"
            />
            {/* End Checkout */}

            {/* Start Status and Tracking */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Order Status and Tracking
                        </h2>
                    )}
                </TextTrigger>
                <FadeUpTrigger>
                    {(ref) => (
                        <>
                            <p className="mt-20" ref={ref}>
                                Delivery status and notification about status
                                updates.
                            </p>
                        </>
                    )}
                </FadeUpTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/voda24/status_tracking.png"
            />
            {/* End Status and Tracking */}

            {/* Start Other Screen */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Other Screen
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/voda24/other_screen.png"
            />
            {/* End Other Screen */}

            {/*Start Box Info With Image*/}
            <BoxImageVerticalParallax
                src="/assets/img/project/voda24/cta.png"
                className="section-margin"
            >
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>Joidy</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                                Let's get in touch
                            </h2>
                            <h6 ref={ref}>
                                Get in touch to find out what we can do
                                together.
                            </h6>
                            <p ref={ref}>
                                We promise that we won’t sell you anything that
                                we can’t deliver. <br></br>
                            </p>
                            <div className="button-box" ref={ref}>
                                <ButtonDefault
                                    text="Let's Talk"
                                    icon={faAngleRight}
                                    href="/contact"
                                />
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
            </BoxImageVerticalParallax>
            {/*End  Box Info With Image*/}
        </>
    )
}

export default Voda24
