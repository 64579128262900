export const baseConfig = {
    mail: 'info@joidy.co',
    // API_URL: 'http://localhost:8082',
    // API_URL: 'https://joidy-serverprod.herokuapp.com',
    // API_URL: 'https://rocky-oasis-38729.herokuapp.com',
    API_URL: 'https://www.joidy.co/',
}

export const engAlpabetRegExp = /^[A-Za-z]+$/

export const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

export const stringRegExp = /^([^0-9]*)$/
