import axios from 'axios'
import { baseConfig } from '../config/baseConfig'

const axiosInstance = axios.create({
    baseURL: baseConfig.API_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
    },
})
  
export default axiosInstance;