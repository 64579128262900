import React from 'react'
import PropTypes from 'prop-types'
import { dsnCN } from '../../hooks/helper'
import JoidyCo from '../JoidyCo'
import LazyImg from '../LazyImg'

import './style.scss'
import { aboutUsData, valuesData } from './config'
import { padding } from '@mui/system'

const displayContent = (models, tag) => {
    const data = models ? aboutUsData : valuesData
    const filtrationContentByTag = (data) =>
        data.filter((el) => (el?.tag?.indexOf(tag) > -1 ? el : !tag && data))

    return (
        <>
            {filtrationContentByTag(data).map((item, index) => {
                return (
                    <a href={item.link} className="value-item" key={index}>
                        {models ? (
                            <>
                                <h4 className="title-block">{item.title}</h4>
                                <p className="mt-15 dsn-auto">
                                    {item.description}
                                </p>
                                <LazyImg
                                    className="values_tow-icon"
                                    src={item.icon}
                                />
                            </>
                        ) : (
                            <>
                                <span className="icon mb-20">
                                    <LazyImg
                                        className="values-icon"
                                        src={item.icon}
                                        alt={item.title}
                                    />
                                    <LazyImg
                                        className="values-icon_hover"
                                        src={item.icon_hover}
                                        alt={item.title}
                                    />
                                </span>
                                <h4 className="title-block mb-20">
                                    {item.title}
                                </h4>
                                <p className="mt-15 dsn-auto">
                                    {item.description}
                                </p>
                            </>
                        )}
                    </a>
                )
            })}
        </>
    )
}

const Values = ({ data, className, models, Advantages, Steps, SmallSteps, tag, ...props }) => {
    return (
        <JoidyCo
            className={dsnCN(
                `dsn-values value-${models ? 'tow' : 'one'}
                ${Advantages && 'feature-item'}
                ${SmallSteps && 'small-steps-item'}
                ${Steps && 'steps-item'}`,
                className
                
            )}
            {...props}
        >
            {displayContent(models, tag)}
        </JoidyCo>
    )
}

Values.propTypes = {}

export default Values
