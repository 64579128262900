import React, {useEffect, useRef} from 'react'
import MetaPost from '../header/MetaPost'
import NavLink from '../button/NavLink'
import { getCareerLink } from '../../api/career/CareerData'
import { dsnCN } from '../../hooks/helper'

const CareerItem = ({ portoDetails, textButton, className }) => {
    const ref = useRef()

    useEffect(() => {
        const video = ref.current.querySelector('video')
        if (!video) return

        video.pause()
        const portItem = ref.current,
            mouseEnter = () => video.play(),
            mouseLeve = () => video.pause()

        portItem.addEventListener('mouseenter', mouseEnter)
        portItem.addEventListener('mouseleave', mouseLeve)

        return () => {
            if (!video) return
            portItem.removeEventListener('mouseenter', mouseEnter)
            portItem.removeEventListener('mouseleave', mouseLeve)
        }
    })

    return (
        <div
            className={dsnCN('career-item text-center v-light-head', className)}
            ref={ref}
        >
            <NavLink to={getCareerLink(portoDetails)}>
                <div className="info-text">
                    {portoDetails.category && (
                        <MetaPost
                            category={portoDetails.category}
                            separate=", "
                        />
                    )}

                    {portoDetails.title && (
                        <h4 className="title-block">{portoDetails.title}</h4>
                    )}

                    <NavLink
                        className="btn-career"
                        to={getCareerLink(portoDetails)}
                    >
                        {textButton}
                    </NavLink>
                </div>
            </NavLink>
        </div>
    )
}

export default React.memo(CareerItem)