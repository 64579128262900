import React from 'react';
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { getPortfolioItem } from "../../api/portfolio/PortfolioData";

import JoidyCo from "../../components/JoidyCo";
import FadeUpTrigger from "../../animation/FadeUpTrigger";
import TextTrigger from "../../animation/TextTrigger";
import TitleCover from "../../components/heading/title-cover/TitleCover";
import BoxGallery from "../../components/box-gallery/BoxGallery";
import ButtonDefault from "../../components/button/button-default/ButtonDefault";
import BoxImageVerticalParallax from "../../components/box-image-vertical/BoxImageVerticalParallax";
import ParallaxImage from "../../components/parallax-image/ParallaxImage";
import NextProject from "../../components/next/NextProject";
import HeaderFull from "../../components/header/HeaderFull";


const AvtoBrama = ({data}) => {
    const nextData = getPortfolioItem(6);

    return (
        <>
            <Helmet>
                <title>JOIDY - {data.title} </title>
                <meta name="description" content={data.description} />
            </Helmet>

            {/*Start Header Half*/}
            <HeaderFull
                heroContent={data}
                parallax={{ yPercent: 30, scale: 1.1 }}
                overlay={data.overlay}
            />
            {/*End Header Half*/}

            {/*Start Info Project*/}
            <Container className="section-margin">
                <JoidyCo col={2} colTablet={1}>
                    <div className="mt-20">
                        <TitleCover>About</TitleCover>
                        <TextTrigger duration={0.8} stagger={0.1}>
                            {(ref) => (
                                <h2 className="title-section" ref={ref}>
                                    Project
                                </h2>
                            )}
                        </TextTrigger>
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <p className="mt-40" ref={ref}>
                                        AvtoBrama company is engaged in
                                        installation and maintenance of gates,
                                        automation, doors, transhipment
                                        equipment, etc. The company was founded
                                        in 2003 and has proven itself to be a
                                        reliable supplier of leading European
                                        manufacturers.
                                    </p>
                                    <ul className="mt-40">
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Domain:{' '}
                                            </strong>{' '}
                                            Industry
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Engagement Model:{' '}
                                            </strong>{' '}
                                            Team
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Duration:{' '}
                                            </strong>{' '}
                                            4 months
                                        </li>
                                        <li className="mt-1" ref={ref}>
                                            <strong className="color-heading">
                                                Technologies:{' '}
                                            </strong>{' '}
                                            PostgreSQL, Redux, React Native,
                                            Firebase, SQLite, AdMob, RoR
                                        </li>
                                    </ul>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                    <div className="mt-20">
                        <TextTrigger duration={0.8} stagger={0.1}>
                            {(ref) => (
                                <h2 className="title-section" ref={ref}>
                                    Challenge
                                </h2>
                            )}
                        </TextTrigger>
                        <FadeUpTrigger>
                            {(ref) => (
                                <>
                                    <h5 className="mt-40" ref={ref}>
                                        Task
                                    </h5>
                                    <p className="mt-10" ref={ref}>
                                        The client wanted to create an app for
                                        IOS which would help customers order
                                        water in a few clicks.
                                    </p>
                                    <h5 className="mt-40" ref={ref}>
                                        Result
                                    </h5>
                                    <p className="mt-10" ref={ref}>
                                        The design has been implemented into a
                                        functional product.
                                    </p>
                                </>
                            )}
                        </FadeUpTrigger>
                    </div>
                </JoidyCo>
            </Container>
            {/*End Info Project*/}

            {/* Start Home iMac */}
            <ParallaxImage
                src="/assets/img/project/avtobrama/home_imac.png"
                triggerHook="bottom"
                parallax={{ scale: 1.2 }}
                parallaxFrom={{ scale: 1 }}
            />
            {/* End Home iMac */}

            {/* Start Typography & Colors */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Typography & Colors
                        </h2>
                    )}
                </TextTrigger>
            </Container>
            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/avtobrama/typography_colors.png"
            />
            {/* End Typography & Colors */}

            {/* Start UI Elements */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            UI Elements
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/avtobrama/ui_elements.png"
            />
            {/* End UI Elements  */}

            {/* Start Catalog */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Catalog
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/avtobrama/catalog.png"
            />
            {/* End Catalog */}

            {/* Start Water List & Cart */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Order Form
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/avtobrama/order_form.png"
            />
            {/* End Water List & Cart */}

            {/* Start Checkout */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Mobile Version
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/avtobrama/mobile_version.png"
            />
            {/* End Checkout */}

            {/* Start Other Screen */}
            <Container className="section-margin text-center">
                <TextTrigger duration={0.8} stagger={0.1}>
                    {(ref) => (
                        <h2 className="title-section" ref={ref}>
                            Other Screens
                        </h2>
                    )}
                </TextTrigger>
            </Container>

            <ParallaxImage
                className="section-margin"
                src="/assets/img/project/avtobrama/other_screens.png"
            />
            {/* End Other Screen */}

            {/*Start Box Info With Image*/}
            <BoxImageVerticalParallax
                src="/assets/img/project/avtobrama/cta.png"
                className="section-margin"
            >
                <FadeUpTrigger>
                    {(ref) => (
                        <JoidyCo col={1} rowGap={30} rowGapTablet={20}>
                            <TitleCover>Joidy</TitleCover>
                            <h2
                                className="title-section border-line-left"
                                ref={ref}
                            >
                                Let's get in touch
                            </h2>
                            <h6 ref={ref}>
                                Get in touch to find out what we can do
                                together.
                            </h6>
                            <p ref={ref}>
                                We promise that we won’t sell you anything that
                                we can’t deliver. <br></br>
                            </p>
                            <div className="button-box" ref={ref}>
                                <ButtonDefault
                                    text="Let's Talk"
                                    icon={faAngleRight}
                                    href="/contact"
                                />
                            </div>
                        </JoidyCo>
                    )}
                </FadeUpTrigger>
            </BoxImageVerticalParallax>
            {/*End  Box Info With Image*/}
        </>
    )
}


export default AvtoBrama;