import React from 'react'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap'
import HeaderNormalTow from '../components/header/HeaderNormalTow'

import JoidyCo from "../components/JoidyCo";

const headerContent = {
    title: `Ready? Let’s estimate!`,
}

const AIChat = () => {
    return ( 
        <>
            <Helmet>
                <title>JOIDY - AI </title>
            </Helmet>            
            {/*Start Header*/}
            <HeaderNormalTow description={headerContent.description}>
                            {headerContent.title}
                        </HeaderNormalTow>

                        {/*Start Contact Form && Info Box*/}
                        <Container>
                            <JoidyCo col={1} colTablet={1}>
                            <iframe src="https://interfaces.zapier.com/embed/page/clovfroj00013l90p8lrriesi?" width="100%" height={640} ></iframe>
                            </JoidyCo>
                        </Container>
                        
                    </>
                )
            }
export default AIChat
